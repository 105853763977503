import React from 'react';
import { useCallback, useEffect, useState } from 'react';
import ExamCreation from '../../modules/exam/ExamCreation';
import { QuestionGenerationParams } from './list/core/_models'
import { CustomCreateExam } from './list/core/_requests'
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { getCourseOutcome } from '../courseoutcome/list/core/_requests'
import Swal from 'sweetalert2';

const CreateExam = () => {
    const location = useLocation()
    const navigate = useNavigate()
    const item: any = location.state
    console.log(item)
    const [outcomes, setOutcomes] = useState([]);
    const course = { id: item.courseId, name: item.name };

    useEffect(() => {
        if (item != undefined) {
            getCourseOutcome(`filter_semesterCourse_id=${item.id}`,
                "Id,Name,Year,Order,SemesterCourse.Name as SemesterCourse", "SemesterCourse").then((res: any) => {
                    setOutcomes(res.data);
                });
        }
    }, [item]);


    const handleSubmit = (params: QuestionGenerationParams[], examDetails: { instructions: string; examType: number; contributionPercentage: number }) => {
        // Backend'e verileri gönderiyoruz
        const payload = {
            questionParams: params,
            semesterCourseId: item.id,
            contributionPercentage: examDetails.contributionPercentage,
            examTypeId: examDetails.examType,
            instructions: examDetails.instructions
        };

        CustomCreateExam(payload)
            .then(response => {
                console.log('Exam created successfully:', response.data);
                if (response.data.toString().startsWith('Hata:')) {
                    Swal.fire({
                        title: 'Hata!',
                        text: response.data.message,
                        icon: 'error',
                        confirmButtonText: 'Tamam'
                    });
                }
                else {
                    Swal.fire({
                        title: 'Başarılı!',
                        text: response.data.message,
                        icon: 'success',
                        confirmButtonText: 'Tamam'
                    });
                    navigate('dashboard');
                }
            })
            .catch(error => {
                console.error('There was an error creating the exam:', error);
                Swal.fire({
                    title: 'Hata!',
                    text: error.data,
                    icon: 'error',
                    confirmButtonText: 'Tamam'
                });
            });
    };

return (
    <div>
        {outcomes.length > 0 && <ExamCreation course={course} outcomes={outcomes} onSubmit={handleSubmit} />}
    </div>
    )
};

export default CreateExam;
