/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */
import React, {lazy, FC, useState} from 'react'
import {Routes, Route, BrowserRouter, Navigate} from 'react-router-dom'
import {PrivateRoutes} from './PrivateRoutes'
import {ErrorsPage} from '../modules/errors/ErrorsPage'
import {Logout, AuthPage, useAuth} from '../modules/auth'
import {App} from '../App'
import {Preview} from '../pages/survey/Preview'
import {SurveyAnswer} from '../pages/survey/StudentSurveys/SurveyAnswer'
import {CourseOutcomeSurvey} from '../pages/survey/StudentSurveys/CourseOutcomeSurvey'
import {OrganizationOutcomeSurvey} from '../pages/survey/StudentSurveys/OrganizationOutcomeSurvey'
import {EducationPurposeOutcomeSurvey} from '../pages/survey/StudentSurveys/EducationPurposeSurvey'
import {RS_SurveyAnswer} from '../pages/survey/ResearcherAndStaffSurveys/RS_SurveyAnswer'

/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const {PUBLIC_URL} = process.env
const AppRoutes: FC = () => {
  const {currentUser} = useAuth()

  return (
    <BrowserRouter basename={PUBLIC_URL}>
      <Routes>
        <Route element={<App />}>
          <Route path='error/*' element={<ErrorsPage />} />
          <Route path='logout' element={<Logout />} />
          <Route path='/surveypreview/:Guid' element={<Preview />} />
          <Route path='/surveyanswer/:Guid/:SurveyAppId/:CourseId' element={<SurveyAnswer />} />
          <Route path='/CourseOutcomeSurvey' element={<CourseOutcomeSurvey />} />
          <Route path='/OrganizationOutcomeSurvey' element={<OrganizationOutcomeSurvey />} />
          <Route
            path='/EducationPurposeOutcomeSurvey'
            element={<EducationPurposeOutcomeSurvey />}
          />
          <Route path='/answersurvey/:Guid/:SurveyAppId/' element={<RS_SurveyAnswer />} />

          {currentUser ? (
            <>
              <Route path='/*' element={<PrivateRoutes />} />
              <Route index element={<Navigate to='/dashboard' />} />
            </>
          ) : (
            <>
              <Route path='auth/*' element={<AuthPage />} />
              <Route path='*' element={<Navigate to='/auth' />} />
            </>
          )}
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export {AppRoutes}
