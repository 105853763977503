import { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { useAuth } from '../core/Auth'
import * as authHelper from '../core/AuthHelpers'
import { getUserByToken, login } from '../core/_requests'





const AutoLogin = () => {
    const params = useParams()
    const navigate = useNavigate()
    const { saveAuth, setCurrentUser } = useAuth()


    useEffect(() => {
        console.log(params.token);
        const guid = params?.token;

        const fetchData = async () => {
            // Base64'i decode etme işlemi
            if (guid) {
                const decodedString: string = atob(guid);
                console.log(decodedString)
                const decodedData: any = JSON.parse(decodedString);
                console.log(decodedData)
                console.log(decodedData.userName)
                const { data: authResponse } = await login(decodedData.userName, decodedData.password, decodedData.userMail);

                //const { data: authResponse } = decodedData;
                const firstRoleAuth = authResponse[0];
                saveAuth(firstRoleAuth);
                authHelper.setAuthRoles({
                    roles: authResponse,
                    activeRoleIndex: 0
                });

                try {
                    const { data: user } = await getUserByToken(firstRoleAuth.token);
                    setCurrentUser(user);
                } catch (error) {
                    console.error('Error fetching user:', error);
                }
            }
        };

        fetchData();
    }, [params]);








    return (
        <>selam</>
    )
}

export { AutoLogin }
