import React, { useEffect, useState } from 'react';
import CourseOutcomeReport from '../../modules/course/CourseOutcomeReport';
import CourseOutcomeReportComponent from '../../modules/course/CourseOutcomeReportAI';
import axios from 'axios'; // API çağrısı için axios kullanacağız

import { useParams, useLocation, useNavigate } from 'react-router-dom';

const API_URL = process.env.REACT_APP_API_URL;
const GET_COURSE_OUTCOME_REPORT = `${API_URL}/Custom/GetCourseOutcomeAverages`;

const CourseOutcomesReport: React.FC = () => {
    const location = useLocation()
    const navigate = useNavigate()
    const item: any = location.state

    const [courseOutcomeData, setCourseOutcomeData] = useState([]);
    const [courseOutcomeAverages, setCourseOutcomeAverages] = useState([]);

    useEffect(() => {
        const fetchCourseOutcomeAverages = async () => {
            try {
                const response = await axios.get(GET_COURSE_OUTCOME_REPORT, { params: { semesterCourseId: item.id } });

                console.log(response.data.map((item: any) => ({
                    value: item.courseOutcomeAverage * 100,
                    label: item.courseOutcomeName,
                })));
                setCourseOutcomeData(
                    response.data.map((item: any) => ({
                        value: parseInt((item?.courseOutcomeAverage * 100).toString()),
                        label: item.courseOutcomeName,
                    }))
                );
                setCourseOutcomeAverages(response.data);
            } catch (error) {
                console.error("Veri çekilirken bir hata oluştu:", error);
            }
        };

        fetchCourseOutcomeAverages();
    }, [item.id]);

    return (
        <div>
            <CourseOutcomeReport courseOutcomeData={courseOutcomeData} />
            <h2 className="mt-3 mb-3">Öğrenme Çıktıları İyileştirme Raporu</h2>
            <CourseOutcomeReportComponent semesterCourseId={ item.id } courseOutcomeAverages={courseOutcomeAverages} />
        </div>
    );
};

export default CourseOutcomesReport;
