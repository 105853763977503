import React, { useEffect, useRef, useState } from "react";
import {
    Editor,
    EditorState,
    RichUtils,
    ContentState,
    convertFromHTML,
    convertToRaw,
} from "draft-js";
import "draft-js/dist/Draft.css";
import "./DraftEditor.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBold, faItalic, faUnderline, faSave, faMagic } from '@fortawesome/free-solid-svg-icons';
import Swal from 'sweetalert2';
import axios from 'axios';
import { stateToHTML } from 'draft-js-export-html'; // HTML export kütüphanesi

const API_URL = process.env.REACT_APP_API_URL;
const GET_COURSE_OUTCOME_REPORT = `${API_URL}/Custom/GetCourseOutcomeReportFromAI`;
const GET_COURSE_OUTCOME_REPORT_DB = `${API_URL}/Custom/GetCourseOutcomeReportFromDb`;
const POST_COURSE_OUTCOME_REPORT = `${API_URL}/Custom/PostCourseOutcomeReport`;

const CourseOutcomeReportComponent = ({ semesterCourseId, courseOutcomeAverages }) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [editorState, setEditorState] = useState(
        EditorState.createWithContent(ContentState.createFromText(""))
    );

    const editor = useRef(null);

    useEffect(() => {
        focusEditor();
        loadSavedReport();
    }, []);

    const focusEditor = () => {
        editor.current.focus();
    };

    const loadSavedReport = async () => {
        setLoading(true);
        setError(null);

        try {
            const response = await fetch(GET_COURSE_OUTCOME_REPORT_DB, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: semesterCourseId,
            });

            if (!response.ok) {
                throw new Error("API request failed");
            }

            const data = await response.json();
            if (data.report) {
                const blocksFromHTML = convertFromHTML(data.report);
                const contentState = ContentState.createFromBlockArray(
                    blocksFromHTML.contentBlocks,
                    blocksFromHTML.entityMap
                );
                setEditorState(EditorState.createWithContent(contentState));
            }
        } catch (err) {
            setError(err.message);
        } finally {
            setLoading(false);
        }
    };

    const saveReport = async () => {
        const contentState = editorState.getCurrentContent();
        const htmlContent = stateToHTML(contentState);

        try {
            const response = await axios.post(POST_COURSE_OUTCOME_REPORT, {
                semesterCourseId,
                reportContent: htmlContent, // HTML formatında kaydediyoruz
            });

            if (response.status === 200) {
                Swal.fire({
                    icon: 'success',
                    title: 'Başarıyla kaydedildi',
                    showConfirmButton: false,
                    timer: 1500
                });
            }
        } catch (error) {
            Swal.fire({
                icon: 'error',
                title: 'Kaydetme başarısız',
                text: error.message,
            });
        }
    };

    const handleKeyCommand = (command) => {
        const newState = RichUtils.handleKeyCommand(editorState, command);
        if (newState) {
            setEditorState(newState);
            return "handled";
        }
        return "not-handled";
    };

    const _onBoldClick = (e) => {
        e.preventDefault();
        setEditorState(RichUtils.toggleInlineStyle(editorState, "BOLD"));
    };

    const _onItalicClick = (e) => {
        e.preventDefault();
        setEditorState(RichUtils.toggleInlineStyle(editorState, "ITALIC"));
    };

    const _onUnderlineClick = (e) => {
        e.preventDefault();
        setEditorState(RichUtils.toggleInlineStyle(editorState, "UNDERLINE"));
    };

    const convertTextToHTML = (text) => {
        const html = text
            .replace(/### (.*?)\n/g, '<h3>$1</h3>')  // ### başlıkları h3 olarak dönüştür
            .replace(/## (.*?)\n/g, '<h2>$1</h2>')   // ## başlıkları h2 olarak dönüştür
            .replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>') // Kalın yazı için
            .replace(/\*(.*?)\*/g, '<em>$1</em>')  // Italik yazı için
            .replace(/\n/g, '<p></p>');  // Yeni satırları paragraf olarak dönüştür

        const blocksFromHTML = convertFromHTML(html);
        const contentState = ContentState.createFromBlockArray(
            blocksFromHTML.contentBlocks,
            blocksFromHTML.entityMap
        );
        setEditorState(EditorState.createWithContent(contentState));
    };

    const confirmAndGetReport = () => {
        Swal.fire({
            title: 'Emin misiniz?',
            text: "Yapay zeka desteği alınarak form doldurulacak, varsa formdaki mevcut bilgiler silinecektir!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Evet, sil!',
            cancelButtonText: 'Hayır, iptal et'
        }).then((result) => {
            if (result.isConfirmed) {
                clearForm();
                getCourseOutcomeReport();
            }
        });
    };

    const clearForm = () => {
        setEditorState(EditorState.createWithContent(ContentState.createFromText("")));
    };

    const getCourseOutcomeReport = async () => {
        setLoading(true);
        setError(null);

        try {
            const response = await fetch(GET_COURSE_OUTCOME_REPORT, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(courseOutcomeAverages),
            });

            if (!response.ok) {
                throw new Error("API request failed");
            }

            const data = await response.json();
            convertTextToHTML(data.response); // Gelen metni HTML'e dönüştür ve editöre aktar
        } catch (err) {
            setError(err.message);
        } finally {
            setLoading(false);
        }
    };

    const currentStyle = editorState.getCurrentInlineStyle();

    return (
        <div className="editor-wrapper mt-3" onClick={focusEditor}>
            <div className="toolbar mb-3">
                <button
                    className={`editorbutton btn btn-sm mr-2 ${currentStyle.has('BOLD') ? 'btn-dark' : 'btn-secondary'}`}
                    onMouseDown={_onBoldClick}
                >
                    <FontAwesomeIcon icon={faBold} />
                </button>
                <button
                    className={`editorbutton btn btn-sm mr-2 ${currentStyle.has('ITALIC') ? 'btn-dark' : 'btn-secondary'}`}
                    onMouseDown={_onItalicClick}
                >
                    <FontAwesomeIcon icon={faItalic} />
                </button>
                <button
                    className={`editorbutton btn btn-sm mr-2 ${currentStyle.has('UNDERLINE') ? 'btn-dark' : 'btn-secondary'}`}
                    onMouseDown={_onUnderlineClick}
                >
                    <FontAwesomeIcon icon={faUnderline} />
                </button>
                <button
                    onClick={confirmAndGetReport}
                    className="editorbutton btn btn-primary btn-sm"
                    style={{ backgroundColor: "pink" }}
                >
                    <FontAwesomeIcon icon={faMagic} /> Laxeri AI
                </button>
                <button
                    onClick={saveReport}
                    className="editorbutton btn btn-success btn-sm ml-2"
                >
                    <FontAwesomeIcon icon={faSave} /> Kaydet
                </button>
            </div>
            <div className="editor-container">
                {loading && (
                    <div className="spinner-border" role="status">
                        <span className="visually-hidden">Yükleniyor...</span>
                    </div>
                )}
                {error && (
                    <div className="alert alert-danger" role="alert">
                        Error: {error}
                    </div>
                )}
                <Editor
                    ref={editor}
                    editorState={editorState}
                    handleKeyCommand={handleKeyCommand}
                    onChange={setEditorState}
                    customStyleMap={{
                        BOLD: { fontWeight: 'bold' },
                        ITALIC: { fontStyle: 'italic' },
                        UNDERLINE: { textDecoration: 'underline' },
                        FONT_SIZE_12: { fontSize: '12px' },
                        FONT_SIZE_16: { fontSize: '16px' },
                        FONT_SIZE_20: { fontSize: '20px' },
                        FONT_SIZE_24: { fontSize: '24px' },
                    }}
                    placeholder="İyileştirme raporunuzu yazmaya başlayabilir ya da Laxeri AI'den yardım isteyebilirsiniz."
                />
            </div>
        </div>
    );
};

export default CourseOutcomeReportComponent;
