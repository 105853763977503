import React, { useEffect, useState } from 'react';
import { Model } from 'survey-core';
import { VisualizationPanel } from 'survey-analytics';

const SurveyResultsComponent = ({ surveyJson, surveyData }) => {
    const [survey, setSurvey] = useState(null);
    const [vizPanel, setVizPanel] = useState(null);

    useEffect(() => {
        // Anket modelini ve görselleştirme panelini yalnızca bir kez oluşturun
        if (!survey) {
            const newSurvey = new Model(surveyJson);
            setSurvey(newSurvey);
        }

        if (!vizPanel && survey) {
            // Container elementini burada belirtmek yerine, doğrudan VisualizationPanel'e geçiriyoruz
            const newVizPanel = new VisualizationPanel(
                survey.getAllQuestions(),
                surveyData, // Bu, bir array olmalıdır; her eleman bir anket cevabını temsil eder
                {} // Burası görselleştirme paneli seçenekleridir, gerekiyorsa doldurun
            );
            newVizPanel.showHeader = true;
            newVizPanel.render("surveyAnalyticsContainer"); // Container ID'si burada belirtilir
            
            setVizPanel(newVizPanel);
        }

        if (vizPanel) {
            survey.getAllQuestions().forEach(function (question) {
                var visualizer = vizPanel.getVisualizer(question.propertyHash.name);
                visualizer.showHeader = false;
                console.log(question.getType());
                // "pie" for "checkbox" and "bar" for "radiogroup"
                /*if (question.getType() === "rating") {
                    visualizer.setChartType("bar");
                }*/
                if (question.getType() === "matrix") {
                    visualizer.setChartType("stackedbar");
                }

            })
        }
    }, [survey, vizPanel, surveyJson, surveyData]); // Bağımlılıklar

    return <div id="surveyAnalyticsContainer"></div>;
};

export default SurveyResultsComponent;
