import {SurveyCreatorComponent, SurveyCreator} from 'survey-creator-react'
import 'survey-core/defaultV2.min.css'
import 'survey-creator-core/survey-creator-core.min.css'
import 'survey-creator-core/survey-creator-core.i18n'
import {localization} from 'survey-creator-core'
localization.currentLocale = 'tr'
const creatorOptions = {
  showLogicTab: false,
  isAutoSave: true,
  showJSONEditorTab: true,
  designerHeight: '700px',
  showSurveyTitle: false,
}

const defaultJson = {
  locale: 'tr',
  pages: [
    {
      name: 'taslak',
      elements: [
        {
          name: 'taslak',
          title: 'Bu taslak bir sorudur:',
          type: 'text',
        },
      ],
    },
  ],
}

export function SurveyCreatorWidget(editSurveyJson: any) {
  // const dt = editSurveyJson != undefined ? editSurveyJson?.editSurveyJson?.surveyJson : '0'
  const creator = new SurveyCreator(creatorOptions)
  creator.showPropertyGrid = false
  creator.text = window.localStorage.getItem('survey-json') || JSON.stringify(defaultJson, null, 3)
  creator.saveSurveyFunc = (saveNo: any, callback: any) => {
    window.localStorage.setItem('survey-json', creator.text)
    callback(saveNo, true)
    // saveSurveyJson(
    //     "https://your-web-service.com/",
    //     creator.JSON,
    //     saveNo,
    //     callback
    // );
  }
  return <SurveyCreatorComponent creator={creator} />
}

// function saveSurveyJson(url, json, saveNo, callback) {
//   const request = new XMLHttpRequest();
//   request.open('POST', url);
//   request.setRequestHeader('Content-Type', 'application/json;charset=UTF-8');
//   request.addEventListener('load', () => {
//       callback(saveNo, true);
//   });
//   request.addEventListener('error', () => {
//       callback(saveNo, false);
//   });
//   request.send(JSON.stringify(json));
// }
