import React, {FC, useState, useEffect} from 'react'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {Model as SurveyModel} from './list/core/_models'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {KTSVG} from '../../../_metronic/helpers'
import {useIntl} from 'react-intl'
import {listSurveyType} from '../surveytype/list/core/_requests'
import {listSemester} from '../semester/list/core/_requests'
import {useQueryResponse} from './list/core/QueryResponseProvider'
import {useListView} from './list/core/ListViewProvider'
import {createSurvey, updateSurvey} from './list/core/_requests'
import {SurveyCreatorWidget} from './list/components/SurveyApp'
import {v4 as uuidv4} from 'uuid'
import {Link, useLocation, useNavigate} from 'react-router-dom'
import Swal from 'sweetalert2'

type Props = {
  //    isPlaceLoading: boolean
  item?: SurveyModel
}

const EditSurveyForm: FC<Props> = ({item}) => {
  const intl = useIntl()
  const {setItemIdForUpdate} = useListView()
  const {refetch} = useQueryResponse()
  const [surveyType, setSurveyType] = React.useState([])
  const [semester, setSemester] = React.useState([])
  const navigate = useNavigate()
  const location = useLocation()
  const state: any = location.state

  const defaultJson = {
    locale: 'tr',
    pages: [
      {
        name: 'taslak',
        elements: [
          {
            name: 'taslak',
            title: 'Bu taslak bir sorudur:',
            type: 'text',
          },
        ],
      },
    ],
  }
  //   console.log(state)

  useEffect(() => {
    listSurveyType('Id, Name').then((res2) => {
      setSurveyType(res2.data || [])
    })
    listSemester('Id, Name').then((res3) => {
      setSemester(res3.data || [])
    })
    window.localStorage.setItem('survey-json', '')

    if (state) {
      formik.setValues(state)

      window.localStorage.setItem('survey-json', state?.surveyJson)
      formik.setFieldValue('surveyJson', state?.surveyJson)
    }
  }, [])

  const [formForEdit] = useState<SurveyModel>({
    ...item,
    draftName: undefined,
    isDraft: undefined,
    surveyTypeId: 0,
    semesterId: 0,
    surveyJSON: undefined,
    guid: undefined,
    courseOutcomeIsChecked: false,
    organizationOutcomeIsChecked: false,
    organizationEducationPurposeIsChecked: false,
  } as SurveyModel)

  const [loading, setLoading] = useState(false)
  const formik = useFormik({
    initialValues: formForEdit,

    onSubmit: async (values) => {
      setLoading(true)
      delete values?.surveyType
      delete values?.semester
      // values.surveyJson = window.localStorage.getItem('survey-json') || state?.surveyJson

      if (window.localStorage.getItem('survey-json')) {
        values.surveyJson = window.localStorage.getItem('survey-json')
      } else if (state?.surveyJson) {
        values.surveyJson = state.surveyJson
      } else {
        values.surveyJson = JSON.stringify(defaultJson) // Default JSON verisi
      }

      if (!values.semesterId && semester.length) {
        values.semesterId = (semester[0] as any)?.id
      }

      if (!values.surveyTypeId && surveyType.length) {
        values.surveyTypeId = (surveyType[0] as any)?.id
      }

      values.guid = uuidv4()

      try {
        if (state) {
          await updateSurvey(values).then((res: any) => {
            if (res.status === 204) {
              // Başarı durumu
              Swal.fire({
                color: '#000000',
                title: 'Anketiniz güncellenmiştir.',
                icon: 'success',
                showCancelButton: false,
                showConfirmButton: false,
                timer: 1500,
              }).then(() => {
                setLoading(false)
                window.localStorage.setItem('survey-json', '')
                navigate('/survey')
                formik.setSubmitting(false)
              })
            } else {
              // Başarısız durumu
              Swal.fire({
                title: 'Hata',
                text: 'Anketiniz güncellenirken bir hata oluştu.',
                icon: 'error',
                confirmButtonText: 'Tamam',
              }).then(() => {
                setLoading(false)
                formik.setSubmitting(false)
              })
            }
          })
        } else {
          await createSurvey(values).then((res: any) => {
            if (res.status === 201) {
              // Başarı durumu
              Swal.fire({
                color: '#000000',
                title: 'Anketiniz başarıyla oluşturulmuştur.',
                icon: 'success',
                showCancelButton: false,
                showConfirmButton: false,
                timer: 1500,
              }).then(() => {
                setLoading(false)
                window.localStorage.setItem('survey-json', '')
                navigate('/survey')
                formik.setSubmitting(false)
              })
            } else {
              // Başarısız durumu
              Swal.fire({
                title: 'Hata',
                text: 'Anketiniz oluşturulurken bir hata oluştu.',
                icon: 'error',
                confirmButtonText: 'Tamam',
              }).then(() => {
                setLoading(false)
                formik.setSubmitting(false)
              })
            }
          })
        }
      } catch (error) {
        Swal.fire({
          title: 'Hata',
          text: 'Bir hata oluştu',
          icon: 'error',
          confirmButtonText: 'Tamam',
        }).then(() => {
          setLoading(false)
          formik.setSubmitting(false)
        })
      }
    },
  })

  return (
    <div className='card mb-5 mb-xl-10'>
      <div
        className='card-header border-0 cursor-pointer'
        role='button'
        data-bs-toggle='collapse'
        data-bs-target='#kt_account_profile_details'
        aria-expanded='true'
        aria-controls='kt_account_profile_details'
      >
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>{intl.formatMessage({id: 'AnketOlustur'})}</h3>
        </div>
      </div>

      <div id='kt_account_profile_details' className='collapse show'>
        <form
          onSubmit={(e) => {
            formik.handleSubmit(e)
          }}
          noValidate
          className='form'
        >
          <div className='card-body border-top p-9'>
            <div className='row mb-3'>
              <label className='col-lg-4 col-form-label  fw-bold fs-6'>
                {intl.formatMessage({id: 'AnketDonemi'})}
              </label>
              <div className='col-lg-8 fv-row'>
                <select
                  className='form-select form-select-solid form-select-md'
                  {...formik.getFieldProps('semesterId')}
                  value={formik.values.semesterId}
                  onChange={formik.handleChange}
                >
                  {semester.map((semester: any) => (
                    <option value={semester?.id as any} key={semester?.id as any}>
                      {semester?.name as any}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className='row mb-10'>
              <label className='col-lg-4 col-form-label  fw-bold fs-6'>
                {intl.formatMessage({id: 'AnketTuru'})}
              </label>
              <div className='col-lg-8 fv-row'>
                <select
                  className='form-select form-select-solid form-select-md'
                  {...formik.getFieldProps('surveyTypeId')}
                  value={formik.values.surveyTypeId}
                  onChange={formik.handleChange}
                >
                  {surveyType.map((surveytype: any) => (
                    <option value={surveytype?.id as any} key={surveytype?.id as any}>
                      {surveytype?.name as any}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className='row mb-6 d-flex '>
              <div className='col-lg-4'>
                {' '}
                <label className='form-label fs-6 fw-bold'>
                  {intl.formatMessage({id: 'DersCiktilariIliski'})}
                </label>
                <div className='form-check form-check-solid form-switch fv-row'>
                  <input
                    {...formik.getFieldProps('courseOutcomeIsChecked')}
                    checked={formik.values.courseOutcomeIsChecked}
                    onChange={(e) =>
                      formik.setFieldValue('courseOutcomeIsChecked', e.target.checked)
                    }
                    value={formik.values.courseOutcomeIsChecked ? 'on' : 'off'}
                    className='form-check-input w-80px mt-2 border-secondary'
                    type='checkbox'
                    id='courseOutcomeIsChecked'
                  />
                </div>
              </div>

              <div className='col-lg-4'>
                {' '}
                <label className='form-label fs-6 fw-bold'>
                  {intl.formatMessage({id: 'OrganizasyonIliski'})}
                </label>
                <div className='form-check form-check-solid form-switch fv-row'>
                  <input
                    {...formik.getFieldProps('organizationOutcomeIsChecked')}
                    checked={formik.values.organizationOutcomeIsChecked}
                    onChange={(e) =>
                      formik.setFieldValue('organizationOutcomeIsChecked', e.target.checked)
                    }
                    value={formik.values.organizationOutcomeIsChecked ? 'on' : 'off'}
                    className='form-check-input w-80px mt-2 border-secondary'
                    type='checkbox'
                    id='organizationOutcomeIsChecked'
                  />
                </div>
              </div>
              <div className='col-lg-4'>
                {' '}
                <label className='form-label fs-6 fw-bold'>
                  {intl.formatMessage({id: 'EgitimAmaclarıIliski'})}
                </label>
                <div className='form-check form-check-solid form-switch fv-row'>
                  <input
                    {...formik.getFieldProps('organizationEducationPurposeIsChecked')}
                    checked={formik.values.organizationEducationPurposeIsChecked}
                    onChange={(e) =>
                      formik.setFieldValue(
                        'organizationEducationPurposeIsChecked',
                        e.target.checked
                      )
                    }
                    value={formik.values.organizationEducationPurposeIsChecked ? 'on' : 'off'}
                    className='form-check-input w-80px mt-2 border-secondary'
                    type='checkbox'
                    id='organizationEducationPurposeIsChecked'
                  />
                </div>
              </div>
            </div>
            <div className='border-top my-10'></div>
            <div>
              <SurveyCreatorWidget editSurveyJson={state} />
            </div>
          </div>

          <div className='card-footer d-flex  py-6 px-9 justify-content-center'>
            <button
              type='submit'
              onClick={() => {
                formik.submitForm()
              }}
              className='btn btn-sm btn-primary btn-active-light-primary w-50 fs-5'
              disabled={loading}
            >
              {!loading && intl.formatMessage({id: 'MODALFORM.SAVE'})}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Lütfen Bekleyiniz...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

export {EditSurveyForm}
