import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../../_metronic/helpers'
import {Model, QueryResponse} from './_models'

const API_URL = process.env.REACT_APP_API_URL
const PROP_URL = `${API_URL}/Survey`
const ADD_SURVEYAPP = `${API_URL}/Custom/AddSurveyApplication`
const DASHBOAR_GET_SURVEY = `${API_URL}/Custom/GetUserSurveyApplications`
const Get_StudentCourseOutcome = `${API_URL}/Custom/GetStudentCourseOutcome`
const Get_StudentOrganizationOutcome = `${API_URL}/Custom/GetStudentOrganizationOutcome`
const Get_StudentOrganizationEducationPurpose = `${API_URL}/Custom/GetStudentOrganizationEducationPurpose`

function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}
const getSurvey = (
  query: string,
  select?: string,
  modelstoinclude?: string
): Promise<QueryResponse> => {
  const qis = query.split('&')
  let newq: Array<string> = []
  qis.map((q) => {
    if (q.startsWith('filter') && q.slice(-1) != '=') {
      if (q.includes('_id')) {
        const aq = capitalizeFirstLetter(
          q.replace('_id', 'Id').replace('filter_', '').replace('=', '*')
        )
        //console.log(aq)
        newq.push(aq)
      } else if (q.includes('strt') && !q.includes(' ')) {
        const aq =
          '(Date)' +
          capitalizeFirstLetter(
            q.replace('strt', '').replace('filter_', '').replaceAll('-', '.').replace('=', '>')
          )
        //console.log(aq)
        newq.push(aq)
      } else if (q.includes('ndnd') && !q.includes(' ')) {
        const aq =
          '(Date)' +
          capitalizeFirstLetter(
            q.replace('ndnd', '').replace('filter_', '').replaceAll('-', '.').replace('=', '<')
          )
        //console.log(aq)
        newq.push(aq)
      } else if (q.includes('String')) {
        const aq = capitalizeFirstLetter(
          q.replace('String', '').replace('filter_', '').replace('=', '_')
        )
        //console.log(aq)
        newq.push(aq)
      } else if (q.includes('stNMB')) {
        const aq = capitalizeFirstLetter(
          q.replace('stNMB', '').replace('filter_', '').replace('=', '>')
        )
        //console.log(aq)
        newq.push(aq)
      } else if (q.includes('enNMB')) {
        const aq = capitalizeFirstLetter(
          q.replace('enNMB', '').replace('filter_', '').replace('=', '<')
        )
        //console.log(aq)
        newq.push(aq)
      } else if (q.includes('BL')) {
        const aq = capitalizeFirstLetter(
          q.replace('BL', '').replace('filter_', '').replace('=', '*')
        )
        //console.log(aq)
        newq.push(aq)
      }
    }
  })
  const newqstring = newq.filter((f) => f).join('|')

  let mti = ''
  if (modelstoinclude != undefined && modelstoinclude != '') {
    mti = '&modelstoinclude=' + modelstoinclude
  }
  let sti = ''
  if (select != undefined && select != '') {
    sti = '&select=' + select
  }

  return axios
    .get(`${PROP_URL}/getAll/${newqstring}?${query}${mti}${sti}`)
    .then((d: AxiosResponse<QueryResponse>) => d.data)
}

const listSurvey = async (select?: string, modelstoinclude?: string): Promise<any> => {
  let mti = ''
  if (modelstoinclude != undefined) {
    mti = '&modelstoinclude=' + modelstoinclude
  }
  return await axios
    .get(`${PROP_URL}/getAll?page=1&items_per_page=99999${mti}&select=${select}`)
    .then((res: AxiosResponse) => {
      return res.data
    })
}

const getSurveyById = (id: ID): Promise<Model | undefined> => {
  return axios
    .get(`${PROP_URL}/${id}`)
    .then((response: AxiosResponse<Response<Model>>) => response.data)
    .then((response: Response<Model>) => response as any)
}

const getSurveyByTypeandSemester = (
  semsesterId: any,
  surveyTypeId: any
): Promise<any | undefined> => {
  return axios
    .get(
      `${PROP_URL}/getAll/SurveyTypeId*${surveyTypeId}|SemesterId*${semsesterId}?page=1&items_per_page=10&modelstoinclude=Semester,SurveyType`
      // Survey/getAll/?page=1&items_per_page=10&modelstoinclude=Semester,SurveyType&SurveyType-1|Semester.IsActive-true
    )
    .then((response: AxiosResponse<Response<any>>) => response.data)
    .then((response: Response<Model>) => response as any)
}
const GetStudentCourseOutcome = (courseId: any): Promise<any | undefined> => {
  return axios
    .get(`${Get_StudentCourseOutcome}?courseId=${courseId}`)
    .then((response: any) => response)
    .then((response: any) => response)
}
const GetStudentOrganizationOutcome = (id: any): Promise<any | undefined> => {
  return axios
    .get(`${Get_StudentOrganizationOutcome}?courseId=${id}`)
    .then((response: any) => response)
    .then((response: any) => response)
}
const GetStudentOrganizationEducationPurpose = (): Promise<any | undefined> => {
  return axios
    .get(`${Get_StudentOrganizationEducationPurpose}`)
    .then((response: any) => response)
    .then((response: any) => response)
}

const createSurvey = (prop: Model): Promise<Model | undefined> => {
  return axios
    .put(PROP_URL, prop)
    .then((response: any) => response)
    .then((response: any) => response)
}

const CustomAddSurveyApp = (appRoleId: any, surveyId: any): Promise<any | undefined> => {
  return axios
    .put(`${ADD_SURVEYAPP}?appRoleId=${appRoleId}&surveyId=${surveyId}`)
    .then((response: any) => response)
    .then((response: any) => response)
}
const CustomGetDashboardSurvey = (): Promise<any | undefined> => {
  return axios
    .get(`${DASHBOAR_GET_SURVEY}`)
    .then((response: any) => response)
    .then((response: any) => response)
}

const updateSurvey = (prop: any): Promise<any | undefined> => {
  return axios
    .post(`${PROP_URL}/${prop.id}`, prop)
    .then((response: any) => response)
    .then((response: any) => response)
}

const deleteSurvey = (propId: ID): Promise<void> => {
  return axios.delete(`${PROP_URL}/${propId}`).then(() => {})
}

const deleteSelectedSurvey = (propIds: Array<ID>): Promise<void> => {
  const requests = propIds.map((id) => axios.delete(`${PROP_URL}/${id}`))
  return axios.all(requests).then(() => {})
}

export {
  getSurvey,
  deleteSurvey,
  deleteSelectedSurvey,
  getSurveyById,
  createSurvey,
  updateSurvey,
  listSurvey,
  CustomAddSurveyApp,
  CustomGetDashboardSurvey,
  GetStudentCourseOutcome,
  GetStudentOrganizationOutcome,
  GetStudentOrganizationEducationPurpose,
  getSurveyByTypeandSemester,
}
