import React from 'react';
import ExamResultsInput from '../../modules/exam/ExamResultInput';

const StudentList: React.FC = () => {
    return (
        <div>
            <ExamResultsInput />
        </div>
    );
};

export default StudentList;
