import React, { useEffect, useState } from 'react';
import ProgramOutcomeContributionReport from '../../modules/programoutcomes/ProgramOutcomeContributionReport';
import axios from 'axios'; // API çağrısı için axios kullanacağız
import { useAuth } from '../../modules/auth/core/Auth'

import { useParams, useLocation, useNavigate } from 'react-router-dom';

const API_URL = process.env.REACT_APP_API_URL;
const GET_COURSE_OUTCOME_REPORT = `${API_URL}/Custom/GetOrganizationOutcomeContributions`;

const OrganizationOutcomeContribution: React.FC = () => {
    const location = useLocation()
    const navigate = useNavigate()
    const item: any = location.state
    const { currentUser } = useAuth()

    const [organizationOutcomeContributions, setOrganizationOutcomeContributions] = useState([]);

    useEffect(() => {
        const fetchCourseOutcomeAverages = async () => {
            try {
                const response = await axios.get(GET_COURSE_OUTCOME_REPORT, { params: { semesterId: item, organizationId: currentUser?.organizationId } });

                setOrganizationOutcomeContributions(response.data.map((item: any) => ({
                    order: item.outcomeOrder,
                    course: item.courseName,
                    outcome: item.outcomeName,
                    contribution: item.contributionCount
                })))
            } catch (error) {
                console.error("Veri çekilirken bir hata oluştu:", error);
            }
        };

        fetchCourseOutcomeAverages();
    }, [item.id]);

    return (
        <div>
            <ProgramOutcomeContributionReport organizationOutcomeContributions={organizationOutcomeContributions} />
        </div>
    );
};

export default OrganizationOutcomeContribution;
