import React from "react";
import { Bar } from "react-chartjs-2";

const ProgramOutcomeContributionReport = ({ organizationOutcomeContributions }) => {
    const getColor = (index) => {
        const colors = ['#6ED3FF', '#1497FF', '#FF5733', '#FFBD33', '#75FF33', '#33FFBD', '#8E44AD', '#2C3E50'];
        return colors[index % colors.length];
    };

    const outcomeOrders = [...new Set(organizationOutcomeContributions.map(item => item.order))];
    const outcomeNames = outcomeOrders.map(order => {
        const outcomeData = organizationOutcomeContributions.find(item => item.order === order);
        return outcomeData ? outcomeData.outcome : '';
    });
    const courseNames = [...new Set(organizationOutcomeContributions.map(item => item.course))];

    const datasets = courseNames.map((course, index) => ({
        label: course,
        backgroundColor: getColor(index),
        data: outcomeOrders.map(order => {
            const outcomeData = organizationOutcomeContributions.find(d => d.order === order && d.course === course);
            return outcomeData ? outcomeData.contribution : 0;
        }),
        stack: 'Stack 0',
    }));

    const chartData = {
        labels: outcomeOrders,
        datasets: datasets,
    };

    const options = {
        responsive: true,
        plugins: {
            title: {
                display: true,
                text: 'Derslerin Program Çıktılarına Toplam Katkısı (Bilgi Paketinde Öğretim Elemanının Doldurduğu Matris)'
            },
            tooltip: {
                callbacks: {
                    title: function (tooltipItems) {
                        const index = tooltipItems[0].dataIndex;
                        return outcomeNames[index];
                    }
                }
            }
        },
        scales: {
            x: {
                stacked: true,
            },
            y: {
                stacked: true,
                beginAtZero: true,
                ticks: {
                    callback: function (value) {
                        if (value < 1000) return value;
                        if (value >= 1000) return +(value / 1000).toFixed(1) + "K";
                    }
                }
            }
        }
    };

    return (
        <div>
            <Bar data={chartData} options={options} />
        </div>
    );
};

export default ProgramOutcomeContributionReport;
