import React, { useEffect, useState } from 'react';
import { Model } from 'survey-core';
import { Tabulator } from 'survey-analytics/survey.analytics.tabulator';
import 'survey-analytics/survey.analytics.tabulator.min.css';
import 'tabulator-tables/dist/css/tabulator.min.css';
import { jsPDF } from "jspdf";
import * as XLSX from 'xlsx';
import "jspdf-autotable";

//window.jsPDF = jsPDF;
window.XLSX = XLSX;
const SurveyTableComponent = ({ surveyJson, surveyData }) => {
    const [surveyModel, setSurveyModel] = useState(null);
    const [tabulator, setTabulator] = useState(null);

    useEffect(() => {
        // Survey modeli yarat
        if (!surveyModel && surveyJson) {
            const newSurveyModel = new Model(surveyJson);
            setSurveyModel(newSurveyModel);
        }

        // Tabulator başlat
        if (!tabulator && surveyModel && surveyData) {
            const newTabulator = new Tabulator(
                surveyModel,
                surveyData,
                {
                    container: "surveyTableContainer", // Tabulator'un yükleneceği container
                    layout: "fitColumns", // Sütunları konteyner genişliğine sığacak şekilde ayarla
                    pagination: "local", // Sayfalama yapısını etkinleştir
                    paginationSize: 10, // Sayfa başına 10 kayıt göster
                    movableColumns: true, // Sütunları yeniden sıralama yeteneği
                    resizableRows: true, // Satırların boyutunu değiştirebilme
                }
            );
            newTabulator.render("surveyTableContainer");
            setTabulator(newTabulator);
        }
    }, [surveyModel, surveyJson, surveyData, tabulator]);

    return (
        <div id="surveyTableContainer" />
    );
};

export default SurveyTableComponent;
