import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {useAuth} from '../../modules/auth'
import {CustomGetDashboardSurvey} from '../survey/list/core/_requests'
import {Link} from 'react-router-dom'

const TeacherAndStaffSurveyBox = () => {
  const intl = useIntl()
  const {currentUser} = useAuth()

  console.log(currentUser)
  const [data, setData]: any = React.useState(null)
  useEffect(() => {
    CustomGetDashboardSurvey().then((res: any) => {
      console.log(res)
      setData(res?.data?.data)
    })
  }, [])
  console.log(data)

  return (
    <>
      <div className={`card card-xl-stretch mb-xl-8`}>
        {/* begin::Header */}
        <div className='card-header border-0 pt-10'>
          <h3 className='card-title align-items-start'>
            <span className='card-label fw-bold fs-3 mb-1'>Değerlendirme Anketleri</span>
          </h3>
        </div>
        <div className='card-body flex-column overflow-auto' style={{maxHeight: '400px'}}>
          {data?.map((item: any, index: any) => (
            <Link
              to={`/answersurvey/${item?.surveyGuid}/${item?.surveyApplicationId}`}
              target='_blank'
              rel='noopener noreferrer'
              style={item?.isCompleted ? {pointerEvents: 'none'} : {pointerEvents: 'auto'}}
            >
              <div
                key={index}
                className={`${
                  item.isCompleted ? 'bg-success' : 'bg-warning'
                } rounded p-4 mb-3 d-flex align-items-center  `}
                style={{
                  opacity: item.isCompleted ? 0.7 : 1,
                  cursor: item.isCompleted ? 'not-allowed' : 'pointer',
                }}
              >
                <span style={{marginRight: '10px'}}>
                  <i className='fa fa-list-alt' style={{fontSize: '1.2em', color: 'black'}}></i>
                </span>
                <div className='m-2'>
                  <h4 className='mb-0'>{item.surveyTypeName}</h4>

                  <h6 className='mb-0 fw-light'>
                    {' '}
                    {item.isCompleted ? 'Tamamlandı' : 'Tamamlanmadı'}
                  </h6>
                </div>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </>
  )
}

export {TeacherAndStaffSurveyBox}
