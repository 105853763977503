import React, { FC, useState, useEffect } from 'react'
import { getSemesterCourse } from '../semestercourse/list/core/_requests'
import { getSurvey } from '../survey/list/core/_requests'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import SurveyResultsComponent from '../../modules/surveyanalytics/surveyanalytics'
import SurveyTableComponent from '../../modules/surveyanalytics/surveyanalyticstable'
import { useAuth } from '../../modules/auth/core/Auth'

const SurveyResultSemester = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const state: any = location.state
    const { currentUser } = useAuth()

    const [surveyResults, setSurveyResults] = React.useState<object[]>()
    const [survey, setSurvey] = React.useState('')

    useEffect(() => {
        if (state) {

            getSemesterCourse(`items_per_page=9999&filter_semester_id=${state}&filter_rOrganization_id=${currentUser?.organizationId}`, "Name,SemesterCourseReport*Name", "SemesterCourseReport").then((res: any) => {
                const dta = res.data.filter((a: any) => a.semesterCourseReportName.length > 0).map((a: any) => JSON.parse(a.semesterCourseReportName[0]));
                //console.log(dta)
                setSurveyResults(dta)
                getSurvey(`items_per_page=9999&filter_surveyType_id=7&filter_semester_id=${state}`, 'Id,Guid,SurveyJson').then((surveyResponse: any) => {
                    const surveyJson = JSON.parse(surveyResponse.data[0].surveyJson);

                    setSurvey(surveyJson);
                })
            })
            //todo: id sabitten çıkartılmalı
            

            //getStudentSemesterCourse(`filter_semesterCourse_id=${state}&items_per_page=9999`, 'Id,SurveyApplication*Name,SurveyApplication*SurveyId,SurveyApplication*Id', 'SurveyApplication').then((res: any) => {
            //    //console.log(res)
            //    //setSurveyResults()
            //    const surveyid = res.data.filter((a: any) => a.surveyApplicationName.length > 0)[0].surveyApplicationSurveyId[0]
            //    const validSaids = res.data.filter((a: any) => a.surveyApplicationId[0] !== undefined).map((a: any) => a.surveyApplicationId[0]);
            //    getSurveyApplication(`filter__id=${validSaids.join(',')}&items_per_page=9999`, 'Id,SurveyApplicationCourseOutcome*Point,SurveyApplicationCourseOutcome*CourseOutcomeId','SurveyApplicationCourseOutcome').then((a: any) => {
            //        //console.log(a.data)
            //        getCourseOutcome(`filter__id=${a.data[0].surveyApplicationCourseOutcomeCourseOutcomeId.join(',')}`).then((resco: any) => {
                        
            //            const surveyResultsC = res.data.filter((a: any) => a.surveyApplicationName.length > 0).map((a: any) => {
            //                const parsedJson = JSON.parse(a.surveyApplicationName);
            //                parsedJson.surveyApplicationId = a.surveyApplicationId[0];  // ID'yi JSON'a ekleyin
            //                return parsedJson;
            //            });
            //            //console.log(surveyResultsC)
            //            const enrichedSurveyResults = surveyResultsC.map((surveyRes: any) => {
            //                const surveyApp = a.data.find((app: any) => app.id === surveyRes.surveyApplicationId);
            //                //console.log(surveyApp);
            //                //console.log(a.data);
            //                if (surveyApp) {
            //                    surveyApp.surveyApplicationCourseOutcomeCourseOutcomeId.forEach((id: any, index: any) => {
            //                        surveyRes['oc_' + id] = surveyApp.surveyApplicationCourseOutcomePoint[index];
            //                    });
            //                }
            //                return surveyRes;
            //            });

            //            // İşlenmiş ve zenginleştirilmiş verileri setSurveyResults'a göndermek
            //            setSurveyResults(enrichedSurveyResults);

            //            //console.log(resco.data)
            //            //console.log(a.data)
            //            const newElements = resco.data.map((co: any) => ({
            //                type: "rating",
            //                name: 'oc_'+co.id.toString(), // CourseOutcomeId kullanılarak name atanır
            //                title: co.name, // CourseOutcome name kullanılarak title atanır
            //                rateCount: 10,
            //                rateMax: 10
            //            }));

            //            getSurvey(`filter__id=${surveyid}`, 'Id,Guid,SurveyJson').then((surveyResponse: any) => {
            //                const surveyJson = JSON.parse(surveyResponse.data[0].surveyJson);
            //                // Yeni oluşturulan elementleri mevcut elements dizisine ekle
            //                surveyJson.pages[0].elements.push(...newElements);
            //                //console.log(surveyJson)
            //                // Güncellenmiş surveyJson ile setSurvey fonksiyonunu çağır
            //                setSurvey(surveyJson);
            //            })
            //        })
            //    })
                //console.log(validSaids)
                
                //console.log(surveyid)
            //})

            //console.log(state)
        }
    }, [state])

    useEffect(() => {
        console.log(surveyResults)
    }, [surveyResults])

    return (
        <>
            {survey !== '' && surveyResults && <div><SurveyResultsComponent surveyJson={survey} surveyData={surveyResults} /><SurveyTableComponent surveyJson={survey} surveyData={surveyResults} /></div>}
        </>
    )
}


export default SurveyResultSemester