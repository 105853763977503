import {
  FC,
  useState,
  useEffect,
  createContext,
  useContext,
  useRef,
  Dispatch,
  SetStateAction,
} from 'react'
import {LayoutSplashScreen} from '../../../../_metronic/layout/core'
import {AuthModel, UserModel} from './_models'
import * as authHelper from './AuthHelpers'
import {getUserByToken} from './_requests'
import {WithChildren} from '../../../../_metronic/helpers'

type AuthContextProps = {
    auth: AuthModel | undefined
    saveAuth: (auth: AuthModel | undefined, roleIndex?: number) => void;
  currentUser: UserModel | undefined
  setCurrentUser: Dispatch<SetStateAction<UserModel | undefined>>
  logout: () => void
}

const initAuthContextPropsState = {
  auth: authHelper.getActiveAuth(),
  saveAuth: () => {},
  currentUser: undefined,
  setCurrentUser: () => {},
  logout: () => {},
}

const AuthContext = createContext<AuthContextProps>(initAuthContextPropsState)

const useAuth = () => {
  return useContext(AuthContext)
}

const AuthProvider: FC<WithChildren> = ({ children }) => {
    // `getAuth` yerine `getActiveAuth` kullanılıyor.
    const [auth, setAuth] = useState<AuthModel | undefined>(authHelper.getActiveAuth());
    const [currentUser, setCurrentUser] = useState<UserModel | undefined>();

    // `saveAuth` artık çoklu token'ları destekleyecek şekilde güncellenmeli.
    const saveAuth = (auth: AuthModel | undefined, roleIndex: number | undefined = undefined) => {
        setAuth(auth);
        if (auth) {
            if (typeof roleIndex === 'number') {
                // Rol seçildiğinde, aktif rolün index'i güncelleniyor.
                authHelper.setActiveRole(roleIndex);
            } else {
                // Eğer rol index'i belirtilmemişse, yeni giriş yapıldığında varsayılan olarak ilk rol seçiliyor.
                const roles = [{ ...auth }]; // Bu, backend'den alınan tüm rollerin listesi olmalı.
                authHelper.setAuthRoles({ roles, activeRoleIndex: 0 });
            }
        } else {
            authHelper.removeAuthRoles();
        }
    };

    const logout = () => {
        saveAuth(undefined);
        setCurrentUser(undefined);
    };

    return (
        <AuthContext.Provider value={{ auth, saveAuth, currentUser, setCurrentUser, logout }}>
            {children}
        </AuthContext.Provider>
    );
};


const AuthInit: FC<WithChildren> = ({children}) => {
  const {auth, logout, setCurrentUser} = useAuth()
  const didRequest = useRef(false)
  const [showSplashScreen, setShowSplashScreen] = useState(true)
  // We should request user by authToken (IN OUR EXAMPLE IT'S API_TOKEN) before rendering the application
  useEffect(() => {
    const requestUser = async (apiToken: string) => {
      try {
        if (!didRequest.current) {
          const {data} = await getUserByToken(apiToken)
          if (data && data?.tenantId > 0) {
            setCurrentUser(data)
          }
        }
      } catch (error) {
        console.error(error)
        if (!didRequest.current) {
          logout()
        }
      } finally {
        setShowSplashScreen(false)
      }

      return () => (didRequest.current = true)
    }

    if (auth && auth.token) {
      requestUser(auth.token)
    } else {
      logout()
      setShowSplashScreen(false)
    }
    // eslint-disable-next-line
  }, [])

  return showSplashScreen ? <LayoutSplashScreen /> : <>{children}</>
}

export {AuthProvider, AuthInit, useAuth}
