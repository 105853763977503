import {useCallback, useEffect, useState} from 'react'
import {getSurvey} from '../list/core/_requests'
import 'survey-core/defaultV2.min.css'
import {StylesManager, Model} from 'survey-core'
import {Survey} from 'survey-react-ui'
import {useParams, useNavigate} from 'react-router-dom'
import {
  getSurveyApplicationById,
  updateSurveyApplication,
} from '../../surveyapplication/list/core/_requests'
import 'survey-core/survey.i18n'

StylesManager.applyTheme('defaultV2')

const RS_SurveyAnswer = () => {
  const params = useParams()
  const navigate = useNavigate()
  const [survey, setSurvey] = useState('')
  const [surveyApplication, setSurveyApplication]: any = useState()
  const [isloading, setisloading] = useState(true)

  useEffect(() => {
    getSurvey(
      'filter_GuidString=' + params.Guid,
      'SurveyJson, id , OrganizationEducationPurposeIsChecked,OrganizationOutcomeIsChecked,CourseOutcomeIsChecked'
    ).then((res) => {
      if (res != undefined) {
        const dt = res.data != undefined ? res.data[0].surveyJson : '0'
        const jsp = JSON.parse(dt || '')
        setSurvey(jsp)

        setisloading(false)
      }
    })

    getSurveyApplicationById(params.SurveyAppId).then((res: any) => {
      setSurveyApplication(res)
    })
  }, [params])

  let md = new Model(survey)
  md.locale = 'tr'
  md.focusFirstQuestionAutomatic = false

  const alertResults = useCallback(
    (sender: any) => {
      const results = JSON.stringify(sender.data)
      let payload: any = surveyApplication
      payload = {...payload, name: results, isCompleted: true}

      updateSurveyApplication(payload).then((res: any) => {
        navigate('/Dashboard')
      })
    },
    [surveyApplication]
  )

  md.onComplete.add(alertResults)

  return !isloading ? (
    <div style={{backgroundColor: '#f3f3f3', height: '100vh'}}>
      {' '}
      <Survey model={md} locale='tr' />{' '}
    </div>
  ) : (
    <div style={{backgroundColor: '#f3f3f3', height: '100vh'}}></div>
  )
}

export {RS_SurveyAnswer}
