import React, { useState, useEffect, useRef } from 'react';
import { ReactGrid } from '@silevis/reactgrid';
import '@silevis/reactgrid/styles.css';
import axios from 'axios';
import Swal from 'sweetalert2';
import { useLocation } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';
import { getExam } from '../../pages/exam/list/core/_requests';
import ReactToPrint from 'react-to-print';

const API_URL = process.env.REACT_APP_API_URL;
const GET_STUDENTS = `${API_URL}/Custom/getStudentsAndQuestions`;
const SAVE_RESULTS = `${API_URL}/Custom/saveResults`;


const StudentExamReport = React.forwardRef(({ students, examQuestions, courseName, courseCode, semester, examName }, ref) => {
    return (
        <div ref={ref}>
            {students.map((student, studentIndex) => (
                <div key={studentIndex} style={{ pageBreakAfter: 'always', padding: '20px' }}>
                    <h2>Öğrenci: {student.name} {student.surname}</h2>
                    <p>Numara: {student.number}</p>

                    {examQuestions.map((question, index) => (
                        <div key={index} style={{ marginTop: '20px' }}>
                            <h2><b>Soru {index + 1}:</b> <div dangerouslySetInnerHTML={{ __html: question.name }}></div></h2>
                            <p>Puan: {question.point}</p>
                            <p>İlişkili Öğrenme Çıktıları: </p>
                            <ul>
                                {question.courseOutcomes.map((outcome, outcomeIndex) => (
                                    <li key={outcomeIndex}>{outcome.order}. {outcome.name}</li>
                                ))}
                            </ul>
                        </div>
                    ))}
                </div>
            ))}
        </div>
    );
});

const HtmlCellTemplate = {
    getCompatibleCell: (uncertainCell) => {
        const value = typeof uncertainCell.value === 'object' ? uncertainCell.value : { order: '', name: '' };
        return { ...uncertainCell, value };
    },
    handleKeyDown: () => false,
    render: (cell) => {
        if (!cell || !cell.value) return null;

        const { order, name, point, courseOutcomes } = cell.value;  // Order ve Name değerlerini hücreden alıyoruz

        const tooltipId = `tooltip-${order}`;

        return (
            <div>
                <a data-tooltip-id={tooltipId}>
                    {order}
                </a><Tooltip
                    id={tooltipId}
                    html={`
        <div style="max-width:800px; word-wrap: break-word; word-break: break-word; white-space: normal;">
            ${name} 
            <p><b>(${point} Puan)</b></p>
            ${courseOutcomes.map(co => `<p>${co.order} - ${co.name}</p>`).join('')}
        </div>
    `}
                />
            </div>
        );
    }
};

const ExamResultsInput = () => {
    const [students, setStudents] = useState([]);
    const [questions, setQuestions] = useState([]);
    const [rows, setRows] = useState([]);
    const [columns, setColumns] = useState([]);
    const [exam, setExam] = useState();
    const [refreshKey, setRefreshKey] = useState(0);

    const location = useLocation();
    const state = location.state; // state'i doğru şekilde alıyoruz

    const componentRef = useRef();

    useEffect(() => {
        getExam(`filter__id=${state}`, "Id,Name,SemesterCourse.Course.Name as CourseName,SemesterCourse.Course.Code as CourseCode,Instructions,QuestionCount,ContributionPercentage,SemesterCourse.Name as SemesterCourse,SemesterCourse.Semester.Name as Semester,ExamType.Name as ExamType", "SemesterCourse.Course,SemesterCourse.Semester,ExamType").then((res) => {
            setExam(res.data[0]);
        });
        
    }, [state]);

    useEffect(() => {
        axios.get(GET_STUDENTS, { params: { examId: state } }) // examId'yi state'den alıyoruz
            .then(response => {
                const { students, examQuestions } = response.data;
                setStudents(students);
                setQuestions(examQuestions);

                // Sütunları oluşturma
                const generatedColumns = [
                    { columnId: 'name', width: 200, resizable: true, title: 'Öğrenci Adı' },
                    { columnId: 'number', width: 100, resizable: true, title: 'Numarası' },
                    ...examQuestions.map(q => ({
                        columnId: `question-${q.id}`,
                        width: 100,
                        resizable: true,
                        title: `${q.order}`,
                        cellTemplate: 'number',
                    })),
                    { columnId: 'total', width: 150, resizable: true, title: 'Toplam Puan', readOnly: true }
                ];
                setColumns(generatedColumns);

                // Başlık satırını oluşturma
                const headerRow: HeaderRow = {
                    rowId: 'header',
                    reorderable: true,
                    resizable: true,
                    cells: [
                        { type: 'header', text: 'Öğrenci Adı' },
                        { type: 'header', text: 'Numarası' },
                        ...examQuestions.map(q => ({
                            type: 'customHtml',
                            value: { order: q.order, name: q.name, point: q.point, courseOutcomes: q.courseOutcomes }  // Order ve Name değerlerini bir obje olarak gönderiyoruz
                        })),
                        { type: 'header', text: 'Toplam' }
                    ]
                };

                // Satırları oluşturma
                const generatedRows = students.map(student => {
                    const examResults = examQuestions.map(q => {
                        const result = student.examResults.find(res => res.examQuestionId === q.id);
                        return result ? result.point || 0 : 0;
                    });

                    const totalPoints = examResults.reduce((sum, current) => sum + current, 0);

                    return {
                        rowId: student.id,
                        cells: [
                            { type: 'text', text: `${student.name} ${student.surname}` },
                            { type: 'text', text: student.number },
                            ...examResults.map(point => ({ type: 'number', value: point })),
                            { type: 'number', value: totalPoints, readOnly: true }
                        ]
                    };
                });

                setRows([headerRow, ...generatedRows]); // Başlık satırını ekliyoruz
            })
            .catch(error => {
                console.error('Error fetching students and questions:', error);
            });
    }, [state, refreshKey]);

    const handleChanges = (changes) => {
        const newRows = [...rows];
        changes.forEach(change => {
            const rowIndex = newRows.findIndex(row => row.rowId === change.rowId);
            const columnIndex = columns.findIndex(col => col.columnId === change.columnId);
            newRows[rowIndex].cells[columnIndex] = { ...newRows[rowIndex].cells[columnIndex], value: change.newCell.value };
        });
        setRows(newRows);
    };

    const saveData = () => {
        console.log(students);
        const formattedResults = students.map(student => {
            const studentRow = rows.find(row => row.rowId === student.id);
            return {
                studentSemesterCourseId: student.studentSemesterCourseId,
                examResults: questions.map(q => {
                    const column = columns.find(col => col.columnId === `question-${q.id}`);
                    const cell = studentRow.cells[columns.indexOf(column)];
                    return {
                        examQuestionId: q.id,
                        point: cell.value,
                    };
                })
            };
        });

        console.log(formattedResults);

        axios.post(SAVE_RESULTS, formattedResults)
            .then(response => {
                console.log('Data saved successfully:', response.data);
                Swal.fire({
                    title: 'Başarılı!',
                    text: 'Kayıt işlemi başarılı.',
                    icon: 'success',
                    confirmButtonText: 'Tamam'
                });
                setRefreshKey(oldKey => oldKey + 1);
            })
            .catch(error => {
                console.error('Error saving data:', error);
                Swal.fire({
                    title: 'Hata!',
                    text: `Bir hata oluştu. ${error.message}`,
                    icon: 'error',
                    confirmButtonText: 'Tamam'
                });
            });
    };

    return (
        <div>
            <h1>{exam?.courseName} ({exam?.courseCode}) dersi, {exam?.semester}, {exam?.examType} sonuçları</h1>
            <ReactToPrint
                trigger={() => <button className='btn btn-warning mt-3'>Sınav Kağıtlarını İndir</button>}
                content={() => componentRef.current}
            />
            <div style={{display:'none'}}>
            <StudentExamReport
                ref={componentRef}
                students={students}
                examQuestions={questions}
                courseName={exam?.courseName}
                courseCode={exam?.courseCode}
                semester={exam?.semester}
                examName={exam?.examType}
                />
                </div>
            <ReactGrid
                rows={rows}
                columns={columns}
                onCellsChanged={handleChanges}
                enableColumnResizing
                headerRowHeight={40}
                enableRangeSelection={true} // Hücre aralığı seçimini etkinleştirir
                customCellTemplates={{ customHtml: HtmlCellTemplate }}
            />
            <button onClick={saveData} className="btn btn-success mt-3">Kaydet</button>
        </div>
    );
};

export default ExamResultsInput;