/* eslint-disable react/jsx-no-target-blank */
import {useIntl} from 'react-intl'
import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'
import { AsideMenuItem } from './AsideMenuItem'
import { useAuth } from '../../../../app/modules/auth'

export function AsideMenuMain() {

    const { currentUser } = useAuth()
    const menus: Array<string> = currentUser?.menus || []
    
  const intl = useIntl()
    const uniqueTags: string[] = []
    Object.keys(menus).map((fullstr: any) => {
        let one = menus[fullstr].split('_')[0].split('|')[1]
        const zero = menus[fullstr].split('_')[0].split('|')[0]
        if (uniqueTags.indexOf(one) === -1 && zero != "main") {
            if (one.split('-').length == 1)
                one = one + '-arrows/arr001.svg'
            uniqueTags.push(one)
            //console.log(one)
        }
    });
  return (
    <>
      <AsideMenuItem
        to='/dashboard'
        icon='/media/icons/duotune/abstract/abs042.svg'
        title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
        fontIcon='bi-app-indicator'
          />
          {
              Object.keys(menus).map((fullstr: any) => {
                  return menus[fullstr].split('_')[0].split('|')[0] == "main" &&
                      <AsideMenuItem
                      to={"/" + menus[fullstr].split('_')[1].split('|')[0]}
                      icon={menus[fullstr].split('_')[1].split('|')[1].split('-').length === 1 ? '/media/icons/duotune/arrows/arr001.svg' : '/media/icons/duotune/' + menus[fullstr].split('_')[1].split('|')[1].split('-')[1]}
                      title={intl.formatMessage({ id: "MENU." + menus[fullstr].split('_')[1].split('|')[1] })}
                          fontIcon='bi-app-indicator'
                      />
              }
              )
          }
          {
              uniqueTags.map((tag: any) =>
              (
                  <AsideMenuItemWithSub
                      to={'/' + tag.split('-')[0]}
                      title={intl.formatMessage({ id: 'MENU.' + tag.split('-')[0] })}
                      fontIcon='bi-sticky'
                      icon={'/media/icons/duotune/' + tag.split('-')[1]}
                  >
                      {Object.keys(menus).map((fullstr: any) => {
                         // console.log(menus[fullstr].split('_')[0].split('|')[1])
                         // console.log(tag.split('-')[0])

                          return menus[fullstr].split('_')[0].split('|')[1].split('-')[0] == tag.split('-')[0] &&
                              <AsideMenuItem
                                  to={"/" + menus[fullstr].split('_')[1].split('|')[0]}
                                  icon={menus[fullstr].split('_')[1].split('|')[1].split('-').length === 1 ? '/media/icons/duotune/arrows/arr001.svg' : '/media/icons/duotune/' + menus[fullstr].split('_')[1].split('|')[1].split('-')[1]}
                              title={intl.formatMessage({ id: "MENU." + menus[fullstr].split('_')[1].split('|')[1].split('-')[0] })}
                                  fontIcon='bi-app-indicator'
                              />
                      }
                      )
                      }

                  </AsideMenuItemWithSub>
              )
              )
          }          
    </>
  )
}
