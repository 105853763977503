import React, { useEffect, useState } from 'react';
import { ChartsWidgetGeneralBar } from '../../../_metronic/partials/widgets'


const CourseOutcomeBarChart = ({ courseOutcomeData }) => {
    
    return (
        <>
            {courseOutcomeData.length > 0 && (
                <ChartsWidgetGeneralBar
                    className='2'
                    title='Öğrenme Çıktıları Sağlanma Yüzdeleri'
                    series={courseOutcomeData.map((a) => a.value)}
                    labels={courseOutcomeData.map((a) => a.label)}
                    name='Öğrenme Çıktıları'
                />
            )}
        </>
    );
};

export default CourseOutcomeBarChart;