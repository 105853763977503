import React, { useState, useEffect } from 'react';
import { Model as Course } from '../../pages/course/list/core/_models'
import { getExamType } from '../../pages/examtype/list/core/_requests'
import { Model as CourseOutcome } from '../../pages/courseoutcome/list/core/_models'
import { QuestionGenerationParams } from '../../pages/exam/list/core/_models'

interface OutcomeParams {
    outcomeId: any;
    params: QuestionGenerationParams[];
}

interface ExamCreationProps {
    course: Course;
    outcomes: CourseOutcome[];
    onSubmit: (params: QuestionGenerationParams[], examDetails: { instructions: string; examType: number; contributionPercentage: number }) => void;
}

const ExamCreation: React.FC<ExamCreationProps> = ({ course, outcomes, onSubmit }) => {
    const [outcomeParams, setOutcomeParams] = useState<OutcomeParams[]>(
        outcomes.map((outcome) => ({
            outcomeId: outcome.id,
            params: [{
                outcomeId: outcome.id,
                difficultyLevel: 1,
                questionCount: 0,
            }],
        }))
    );

    useEffect(() => {
        getExamType("items_per_page=999", "Id,Name").then((res: any) => {
            console.log(res.data);
            if (res.data)
                setExamTypes(res.data);
            else
                alert("sınav türleri bulunamadı");
        });
    }, []);

    // Yeni alanlar için state'ler
    const [instructions, setInstructions] = useState('');
    const [examType, setExamType] = useState(1);
    const [examTypes, setExamTypes] = useState([]);
    const [contributionPercentage, setContributionPercentage] = useState(100);

    const handleParamChange = (outcomeId: any, index: number, field: keyof QuestionGenerationParams, value: number) => {
        setOutcomeParams((prevOutcomeParams) =>
            prevOutcomeParams.map((outcomeParam) =>
                outcomeParam.outcomeId === outcomeId
                    ? {
                        ...outcomeParam,
                        params: outcomeParam.params.map((param, i) =>
                            i === index ? { ...param, [field]: value } : param
                        ),
                    }
                    : outcomeParam
            )
        );
    };

    const addNewParamSet = (outcomeId: any) => {
        setOutcomeParams((prevOutcomeParams) =>
            prevOutcomeParams.map((outcomeParam) =>
                outcomeParam.outcomeId === outcomeId
                    ? {
                        ...outcomeParam,
                        params: [
                            ...outcomeParam.params,
                            { outcomeId, difficultyLevel: 1, questionCount: 0 },
                        ],
                    }
                    : outcomeParam
            )
        );
    };

    const removeParamSet = (outcomeId: any, index: number) => {
        setOutcomeParams((prevOutcomeParams) =>
            prevOutcomeParams.map((outcomeParam) =>
                outcomeParam.outcomeId === outcomeId
                    ? {
                        ...outcomeParam,
                        params: outcomeParam.params.filter((_, i) => i !== index),
                    }
                    : outcomeParam
            )
        );
    };

    const handleSubmit = (event: React.FormEvent) => {
        event.preventDefault();
        const allParams = outcomeParams.flatMap(outcomeParam => outcomeParam.params);
        onSubmit(allParams.filter(param => param.questionCount > 0), { instructions, examType, contributionPercentage });
    };

    return (
        <div className='container'>
            <h2 className='text-center my-4'>{course.name} dersi - sınav oluştur</h2>
            <form onSubmit={handleSubmit}>
                <div className='card mb-4'>
                    <div className='card-body border-top p-9'>
                        <div className='mb-3'>
                            <label className='required fw-bold fs-6 mb-2'>Talimatlar:</label>
                            <textarea
                                className='form-control'
                                value={instructions}
                                onChange={(e) => setInstructions(e.target.value)}
                            />
                        </div>
                        <div className='mb-3'>
                            <label className='required fw-bold fs-6 mb-2'>Sınav Türü:</label>
                            <select
                                className='form-select'
                                value={examType}
                                onChange={(e) => setExamType(Number(e.target.value))}
                            >
                                {examTypes.map((a: any) => (
                                    <option key={a.id} value={a.id}>{a.name}</option>
                                ))}
                            </select>
                        </div>
                        <div className='mb-3'>
                            <label className='required fw-bold fs-6 mb-2'>Katkı Yüzdesi (Bulunulan gruba göre girilmelidir. Tek ara sınav yapılacak ise bu değer 100 olmalıdır. Final ve Bütünlemelerde de 100 olmalıdır.):</label>
                            <input
                                type="number"
                                min="0"
                                max="100"
                                className='form-control'
                                value={contributionPercentage}
                                onChange={(e) => setContributionPercentage(Number(e.target.value))}
                            />
                        </div>
                    </div>
                </div>
                {outcomes.map((outcome) => (
                    <div key={outcome.id} className='card mb-4'>
                        <div className='card-header'>
                            <h4 className='card-title'>{outcome.name}</h4>
                        </div>
                        <div className='card-body border-top p-9'>
                            {outcomeParams.find(o => o.outcomeId === outcome.id)?.params.map((param, index) => (
                                <div key={index} className='fv-row mb-7'>
                                    <div className='mb-3'>
                                        <label className='required fw-bold fs-6 mb-2'>Zorluk düzeyi:</label>
                                        <select
                                            className='form-select'
                                            value={param.difficultyLevel}
                                            onChange={(e) =>
                                                handleParamChange(outcome.id, index, 'difficultyLevel', Number(e.target.value))
                                            }
                                        >
                                            <option value="1">1</option>
                                            <option value="2">2</option>
                                            <option value="3">3</option>
                                            <option value="4">4</option>
                                            <option value="5">5</option>
                                        </select>
                                    </div>
                                    <div className='mb-3'>
                                        <label className='required fw-bold fs-6 mb-2'>Sorulacak soru adedi:</label>
                                        <input
                                            type="number"
                                            min="0"
                                            className='form-control'
                                            value={param.questionCount}
                                            onChange={(e) =>
                                                handleParamChange(outcome.id, index, 'questionCount', Number(e.target.value))
                                            }
                                        />
                                    </div>
                                    <button type="button" className='btn btn-danger' onClick={() => removeParamSet(outcome.id, index)}>
                                        Sil
                                    </button>
                                </div>
                            ))}
                            <button type="button" className='btn btn-primary' onClick={() => addNewParamSet(outcome.id)}>
                                Farklı bir kural ekle
                            </button>
                        </div>
                    </div>
                ))}
                <div className='text-center'>
                    <button type="submit" className='btn btn-success'>Sınav oluştur</button>
                </div>
            </form>
        </div>
    );
};

export default ExamCreation;
