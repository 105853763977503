import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {
  StudentPostponement,
  GetStudentPostponementInfo,
} from '../../courseorganizationoutcomematrix/list/core/_requests'
import Swal from 'sweetalert2'

const SurveyPostpone = () => {
  const intl = useIntl()

  const [data, setData]: any = React.useState(null)

  useEffect(() => {
    GetStudentPostponementInfo().then((res: any) => {
      console.log(res)
      setData(res.data.data[0])
    })
  }, [])

  console.log(data)

  const StudentSurveyPostpone = () => {
    Swal.fire({
      color: '#000000',
      title: 'İşlem sonrasında 2 adet erteleme hakkınız kalacaktır , onaylıyor musunuz?',
      icon: 'question',
      showCancelButton: true,
      showConfirmButton: true,
      confirmButtonText: 'Evet',
      cancelButtonText: 'Hayır',
    }).then((result) => {
      if (result.isConfirmed) {
        StudentPostponement().then((res: any) => {
          console.log(res)
          if (res.status == 200) {
            Swal.fire({
              color: '#000000',
              title: 'İşlem Başarılı',
              icon: 'success',
              showCancelButton: false,
              showConfirmButton: false,
              timer: 1500,
            })
          } else {
            Swal.fire({
              color: '#000000',
              title: 'Bir Hata oluştu',
              icon: 'error',
              showCancelButton: false,
              showConfirmButton: false,
              timer: 1500,
            })
          }
        })
      }
    })
  }

  return (
    <>
      <div className='alert alert-warning d-flex align-items-center p-5 mb-10 mt-10 d-flex flex-column flex-md-row'>
        <div className='col-12 col-md-8'>
          <span className='svg-icon svg-icon-2hx svg-icon-warning me-3 m-2'>
            {' '}
            <i className='bi bi-exclamation-triangle bi-lg fs-1'></i>
          </span>

          <div className='d-flex flex-column m-2'>
            <h5 className='mb-2'>Bilgilendirme</h5>
            {data?.isPostponed ? (
              <>
                {' '}
                <span>{`Şu an Aktif bir ertelem hakkınız bulunmakta .`}</span>{' '}
                <span>{`Bu hak aktif edildikten sonraki 24 saat için geçerli olacaktır.`}</span>{' '}
              </>
            ) : data?.remainingPostponementCount > 0 ? (
              <>
                <span className='mt-2'>
                  {`Sınav sonuçlarınızı anketlerinizi tamamlamadan görebilmek için ${data?.remainingPostponementCount} ertleme hakkınız bulunmaktadır.`}
                </span>
                <span>{`Bu hak aktif edildikten sonraki 24 saat için geçerli olacaktır.`}</span>
              </>
            ) : (
              <span>{`Ertleme Hakkınız kalmamıştır.`}</span>
            )}
          </div>
        </div>
        <div className='col-12 col-md-4  d-flex justify-content-center align-items-end p-5'>
          {!data?.isPostponed && data?.remainingPostponementCount > 0 ? (
            <button type='button' className='btn btn-warning w-100' onClick={StudentSurveyPostpone}>
              Ertele
            </button>
          ) : null}
        </div>
      </div>
    </>
  )
}

export {SurveyPostpone}
