import { AuthModel } from './_models';

const AUTH_LOCAL_STORAGE_KEY = 'kt-auth-react-v';
const AUTH_ROLES_LOCAL_STORAGE_KEY = 'kt-auth-roles-react-v';

// AuthModel güncellenmeli, birden fazla rolü ve token'ı destekleyecek şekilde genişletilmeli
interface AuthRolesModel {
    roles: AuthModel[]; // Kullanıcının sahip olduğu tüm roller ve token'lar
    activeRoleIndex: number; // Aktif olan rolün index'i
}

const getAuthRoles = (): AuthRolesModel | undefined => {
    if (!localStorage) {
        return;
    }

    const lsValue: string | null = localStorage.getItem(AUTH_ROLES_LOCAL_STORAGE_KEY);
    if (!lsValue) {
        return;
    }

    try {
        const authRoles: AuthRolesModel = JSON.parse(lsValue) as AuthRolesModel;
        if (authRoles) {
            return authRoles;
        }
    } catch (error) {
        console.error('AUTH ROLES LOCAL STORAGE PARSE ERROR', error);
    }
};

const setAuthRoles = (authRoles: AuthRolesModel) => {
    if (!localStorage) {
        return;
    }

    try {
        const lsValue = JSON.stringify(authRoles);
        localStorage.setItem(AUTH_ROLES_LOCAL_STORAGE_KEY, lsValue);
    } catch (error) {
        console.error('AUTH ROLES LOCAL STORAGE SAVE ERROR', error);
    }
};

const setActiveRole = (index: number) => {
    const authRoles = getAuthRoles();
    if (authRoles) {
        authRoles.activeRoleIndex = index;
        setAuthRoles(authRoles);
    }
};

const getActiveAuth = (): AuthModel | undefined => {
    const authRoles = getAuthRoles();
    if (authRoles && authRoles.roles.length > 0) {
        return authRoles.roles[authRoles.activeRoleIndex];
    }
};

const removeAuthRoles = () => {
    if (!localStorage) {
        return;
    }

    try {
        localStorage.removeItem(AUTH_ROLES_LOCAL_STORAGE_KEY);
    } catch (error) {
        console.error('AUTH ROLES LOCAL STORAGE REMOVE ERROR', error);
    }
};

// Axios setup fonksiyonu güncellenmeli, aktif rol için token'ı kullanmalı
export function setupAxios(axios: any) {
    axios.defaults.headers.Accept = 'application/json';
    axios.interceptors.request.use(
        (config: { headers: { Authorization: string } }) => {
            const auth = getActiveAuth();
            if (auth && auth.token) {
                config.headers.Authorization = `Bearer ${auth.token}`;
            }

            return config;
        },
        (err: any) => Promise.reject(err)
    );
}

export { getAuthRoles, setAuthRoles, setActiveRole, getActiveAuth, removeAuthRoles, AUTH_LOCAL_STORAGE_KEY };
