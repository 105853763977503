import { localization } from "../localizationManager";

export var russianStrings = {
  groupButton: "Сгруппировать",
  ungroupButton: "Разгруппировать",
  selectButton: "Выбрать",
  hideColumn: "Скрыть столбец",
  showColumn: "Показать столбец",
  makePrivateColumn: "Сделать столбец приватным",
  makePublicColumn: "Сделать столбец публичным",
  moveToDetail: "Спрятать в раскрывающуюся секцию",
  filter: "Фильтр",
  showAsColumn: "Показать как столбец",
  visualizer_text: "Таблица",
  visualizer_wordcloud: "Облако тэгов",
  visualizer_histogram: "Гистограмма",
  visualizer_number: "Среднее значение",
  visualizer_choices: "Таблица",
  visualizer_selectBase: "Диаграмма",
  chartType_vbar: "Вертикальная столбчатая диаграмма",
  chartType_bar: "Столбчатая диаграмма",
  chartType_stackedbar: "Столбчатая диаграмма с накоплением",
  chartType_doughnut: "Кольцевая диаграмма",
  chartType_pie: "Круговая диаграмма",
  chartType_scatter: "Точечная диаграмма",
  chartType_gauge: "Измерительная диаграмма",
  chartType_bullet: "Маркированная диаграмма",
  hideButton: "Скрыть",
  showButton: "Показать",
  resetFilter: "Очистить фильтр",
  changeLocale: "Сменить язык",
  clearButton: "Очистить",
  addElement: "Выберите вопрос...",
  defaultOrder: "Порядок по умолчанию",
  ascOrder: "Сортировать по возрастанию",
  descOrder: "Сортировать по убыванию",
  showMinorColumns: "Показать второстепенные столбцы",
  otherCommentTitle: "Другое и комментарии",
  noVisualizerForQuestion: "Этот тип вопроса не поддерживается",
  noResults: "Результаты отсутствуют",
  filterPlaceholder: "Поиск...",
  removeRows: "Удалить строки",
  showLabel: "Показать",
  entriesLabel: "записей",
  makePrivateButton: "Сделать приватным",
  makePublicButton: "Сделать публичным",
  showPercentages: "Показать проценты",
  hidePercentages: "Скрыть проценты",
  pdfDownloadCaption: "PDF",
  xlsxDownloadCaption: "Excel",
  csvDownloadCaption: "CSV",
  saveDiagramAsPNG: "Скачать диаграмму в формате PNG",
  hideEmptyAnswers: "Скрыть пустые ответы",
  showEmptyAnswers: "Показать пустые ответы",
  "topNValueText-1": "Все ответы",
  "topNValueText5": "Первые 5 ответов",
  "topNValueText10": "Первые 10 ответов",
  "topNValueText20": "Первые 20 ответов",
  hideMissingAnswers: "Скрыть отсутствующие ответы",
  showMissingAnswers: "Показать отсутствующие ответы",
  missingAnswersLabel: "Отсутствующие ответы",
  showPerValues: "По значениям",
  showPerColumns: "По столбцам",
  answer: "Ответ",
  correctAnswer: "Правильный ответ: ",
  percent: "процентов",
  responses: "ответов"
};

localization.locales["ru"] = russianStrings;
localization.localeNames["ru"] = "Русский";
