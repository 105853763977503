import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {GetStudentCourseOutcome} from '../list/core/_requests'
import {useAuth} from '../../../modules/auth'
import {useLocation} from 'react-router-dom'
import {
  createSurveyApplicationCourseOutcome,
  IsAnsweredSurveyAppCourseOutcome,
} from '../../surveyapplication/list/core/_requests'
import {useNavigate} from 'react-router-dom'
import Swal from 'sweetalert2'

const CourseOutcomeSurvey = () => {
  const intl = useIntl()
  const navigate = useNavigate()
  const {currentUser} = useAuth()
  const [selected, setSelected]: any = React.useState([])
  const [data, setData]: any = React.useState(null)

  const location = useLocation()
  let payload: any = location.state

  useEffect(() => {
    console.log(payload)
    if (payload) {
      IsAnsweredSurveyAppCourseOutcome(payload[4]?.surveyApplicationId).then((res: any) => {
        console.log(res)
        if (currentUser && !res?.data) {
          GetStudentCourseOutcome(payload[3]?.courseId).then((res: any) => {
            setData(res?.data?.data)
            res?.data?.data.map((item: any) => {
              setSelected((prevSelected: any) => [
                ...prevSelected,
                {courseOutcome: item?.id, point: null},
              ])
            })
          })
        } else {
          if (payload[1].organizationOutcome) {
            navigate('/OrganizationOutcomeSurvey', {state: payload})
          } else if (payload[2].organizationEducationPurpose) {
            navigate('/EducationPurposeOutcomeSurvey', {state: payload})
          } else {
            navigate('/dashboard')
          }
        }
      })
    }
  }, [currentUser, payload])

  const SaveAnswer = async () => {
    if (selected.some((item: any) => item.point === null)) {
      Swal.fire({
        title: 'Hata',
        text: 'Lütfen tüm sorulara cevap veriniz.',
        icon: 'error',
        confirmButtonText: 'Tamam',
      })
    } else {
      try {
        await Promise.all(
          selected?.map(async (item: any) => {
            let put = {
              surveyApplicationId: payload[4]?.surveyApplicationId,
              courseOutcomeId: item?.courseOutcome,
              point: item?.point,
            }
            await createSurveyApplicationCourseOutcome(put)
          })
        )

        if (payload[1].organizationOutcome) {
          navigate('/OrganizationOutcomeSurvey', {state: payload})
        } else if (payload[2].organizationEducationPurpose) {
          navigate('/EducationPurposeOutcomeSurvey', {state: payload})
        } else {
          navigate('/dashboard')
        }
      } catch (error) {
        // Handle errors if any
        console.error('Error:', error)
      }
    }
  }

  const handleChange = (i: any, point: any) => {
    // Eğer sadece ilk elemanın point değerini 2 yapmak istiyorsanız

    const updatedSelected = [
      ...selected.slice(0, i),
      {
        ...selected[i],
        point: point,
      },
      ...selected.slice(i + 1),
    ]

    setSelected(updatedSelected)
  }

  return (
    <div className='mx-0 mx-sm-auto  mt-16 ' style={{minWidth: '500px'}}>
      <div className='card'>
        <div className='card-body'>
          <div className='text-center'>
            <i className='far fa-file-alt fa-4x mb-3 text-primary'></i>
            <p>
              <strong className='fs-3'> Ders Çıktıları Anketi</strong>
            </p>
            <></>
            <p>
              <strong className='fs-3'>
                Aşağıdaki öğrenme çıktılarını ne düzeyde öğrendiğinizi düşünüyorsunuz?
              </strong>
            </p>
          </div>

          <hr />
          {!!data &&
            data.map((item: any, i: any) => {
              return (
                <form className='px-4 mb-10 mt-16' action=''>
                  <p className='text-center'>
                    {/* <strong>Soru-{i + 1} </strong> */}
                    <strong className='fs-3'> {item?.name}</strong>
                  </p>
                  <div className='d-flex flex-row gap-0 mt-10 '>
                    <div className=' w-100 mb-2 d-flex flex-column p-0 justify-content-center align-items-center text-center '>
                      <p className=' fs-4 p-0  fw-bold'>1</p>
                      <input
                        className='form-check-input w-30px h-30px border border-5 border-secondary shadow'
                        type='radio'
                        name='exampleForm'
                        id='1'
                        onChange={(e: any) => handleChange(i, 1)}
                      />
                    </div>
                    <div className=' w-100 mb-2 d-flex flex-column p-0 justify-content-center align-items-center text-center '>
                      <p className=' fs-4 p-0  fw-bold'>2</p>
                      <input
                        className='form-check-input w-30px h-30px border border-5 border-secondary shadow'
                        type='radio'
                        name='exampleForm'
                        id='2'
                        onChange={(e: any) => handleChange(i, 2)}
                      />
                    </div>
                    <div className=' w-100 mb-2 d-flex flex-column p-0 justify-content-center align-items-center text-center '>
                      <p className=' fs-4 p-0  fw-bold'>3</p>
                      <input
                        className='form-check-input w-30px h-30px border border-5 border-secondary shadow'
                        type='radio'
                        name='exampleForm'
                        id='3'
                        onChange={(e: any) => handleChange(i, 3)}
                      />
                    </div>
                    <div className=' w-100 mb-2 d-flex flex-column p-0 justify-content-center align-items-center text-center '>
                      <p className=' fs-4 p-0  fw-bold'>4</p>
                      <input
                        className='form-check-input w-30px h-30px border border-5 border-secondary shadow'
                        type='radio'
                        name='exampleForm'
                        id='4'
                        onChange={(e: any) => handleChange(i, 4)}
                      />
                    </div>
                    <div className=' w-100 mb-2 d-flex flex-column p-0 justify-content-center align-items-center text-center '>
                      <p className=' fs-4 p-0  fw-bold'>5</p>
                      <input
                        className='form-check-input w-30px h-30px border border-5 border-secondary shadow'
                        type='radio'
                        name='exampleForm'
                        id='5'
                        onChange={(e: any) => handleChange(i, 5)}
                      />
                    </div>
                    <div className=' w-100 mb-2 d-flex flex-column p-0 justify-content-center align-items-center text-center '>
                      <p className=' fs-4 p-0  fw-bold'>6</p>
                      <input
                        className='form-check-input w-30px h-30px border border-5 border-secondary shadow'
                        type='radio'
                        name='exampleForm'
                        id='6'
                        onChange={(e: any) => handleChange(i, 6)}
                      />
                    </div>
                    <div className=' w-100 mb-2 d-flex flex-column p-0 justify-content-center align-items-center text-center '>
                      <p className=' fs-4 p-0  fw-bold'>7</p>
                      <input
                        className='form-check-input w-30px h-30px border border-5 border-secondary shadow'
                        type='radio'
                        name='exampleForm'
                        id='7'
                        onChange={(e: any) => handleChange(i, 7)}
                      />
                    </div>
                    <div className=' w-100 mb-2 d-flex flex-column p-0 justify-content-center align-items-center text-center '>
                      <p className=' fs-4 p-0  fw-bold'>8</p>
                      <input
                        className='form-check-input w-30px h-30px border border-5 border-secondary shadow'
                        type='radio'
                        name='exampleForm'
                        id='8'
                        onChange={(e: any) => handleChange(i, 8)}
                      />
                    </div>
                    <div className=' w-100 mb-2 d-flex flex-column p-0 justify-content-center align-items-center text-center '>
                      <p className=' fs-4 p-0  fw-bold'>9</p>
                      <input
                        className='form-check-input w-30px h-30px border border-5 border-secondary shadow'
                        type='radio'
                        name='exampleForm'
                        id='9'
                        onChange={(e: any) => handleChange(i, 9)}
                      />
                    </div>
                    <div className=' w-100 mb-2 d-flex flex-column p-0 justify-content-center align-items-center text-center '>
                      <p className=' fs-4 p-0  fw-bold'>10</p>
                      <input
                        className='form-check-input w-30px h-30px border border-5 border-md-1 border-secondary shadow-sm'
                        type='radio'
                        name='exampleForm'
                        id='10'
                        onChange={(e: any) => handleChange(i, 10)}
                      />
                    </div>
                  </div>
                </form>
              )
            })}
        </div>
        <div className='card-footer text-end'>
          <button type='button' className='btn btn-primary' onClick={(e: any) => SaveAnswer()}>
            Onayla
          </button>
        </div>
      </div>
    </div>
  )
}

export {CourseOutcomeSurvey}
