import {useCallback, useEffect, useState} from 'react'
import { getSurvey } from './list/core/_requests'
import { Model as SurveyModel } from './list/core/_models'
import {createSurveyApplication} from '../surveyapplication/list/core/_requests'
import {Model as SurveyApplicationModel} from '../surveyapplication/list/core/_models'
import 'survey-core/defaultV2.min.css'
import {StylesManager, Model} from 'survey-core'
import {Survey} from 'survey-react-ui'
import {useParams} from 'react-router-dom'
import 'survey-core/survey.i18n'

StylesManager.applyTheme('defaultV2')

const Preview = () => {
  const params = useParams()
    const [survey, setSurvey] = useState('')
    const [isloading, setisloading] = useState(true)
    const [surveyid, setsurveyid] = useState(0)
    
  useEffect(() => {
    getSurvey('filter_GuidString=' + params.Guid, 'Id,SurveyJson').then((res) => {
      if (res != undefined) {
          const dt = res.data != undefined ? res.data[0].surveyJson : '0'
          const idt = res.data != undefined ? res.data[0].id : 0
          const jsp = JSON.parse(dt)
          console.log(idt)
          setSurvey(jsp)
          setsurveyid(parseInt(idt?.toString() || '0'))
        setisloading(false)
      }
    })
  }, [params])

  const alertResults = useCallback((sender: any) => {
      const results = JSON.stringify(sender.data)
      const samodel = { name: results, surveyId: surveyid }
      createSurveyApplication(samodel)
    alert(results)
  }, [])

let md = new Model(survey)
      md.focusFirstQuestionAutomatic = false
      md.locale = 'tr'
    md.onComplete.add(alertResults)

  return !isloading ? (
    <div style={{backgroundColor: '#f3f3f3', height: '100vh'}}>
      <Survey model={md} />{' '}
    </div>
  ) : (
    <div></div>
  )
}

export {Preview}
