import React, { FC, useState, useEffect } from 'react'
import { getSurvey } from '../survey/list/core/_requests'
import { getStudentSemesterCourse } from '../studentsemestercourse/list/core/_requests'
import { getSurveyApplication } from '../surveyapplication/list/core/_requests'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import SurveyResultsComponent from '../../modules/surveyanalytics/surveyanalytics'

const SurveyResult = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const state: any = location.state

    const [surveyResults, setSurveyResults] = React.useState([])
    const [survey, setSurvey] = React.useState('')


    useEffect(() => {
        if (state) {
            getStudentSemesterCourse(`filter_semesterCourse_id=${state}&items_per_page=9999`, 'Id,SurveyApplication*Name,SurveyApplication*SurveyId', 'SurveyApplication').then((res: any) => {
                //console.log(res)
                setSurveyResults(res.data.filter((a: any) => a.surveyApplicationName.length > 0).map((a: any) => JSON.parse(a.surveyApplicationName)))
                const surveyid = res.data.filter((a: any) => a.surveyApplicationName.length > 0)[0].surveyApplicationSurveyId[0]
                getSurvey(`filter__id=${surveyid}`, 'Id,Guid,SurveyJson').then((ressurvey: any) => {
                    setSurvey(ressurvey.data.map((a: any) => JSON.parse(a.surveyJson))[0])
                })
                console.log(surveyid)
            })

            //console.log(state)
        }
    }, [state])

    useEffect(() => {
        console.log(surveyResults)
    }, [surveyResults])

    return (
        <>
            {survey !== '' && surveyResults && <SurveyResultsComponent surveyJson={survey} surveyData={surveyResults} />}
        </>
    )
}


export default SurveyResult