import {lazy, FC, Suspense} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {DashboardPage} from '../pages/dashboard/DashboardWrapper'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {DisableSidebar} from '../../_metronic/layout/core'
import {WithChildren} from '../../_metronic/helpers'
import {EditSurveyForm} from '../pages/survey/AddUpdate'
import SurveyResult from '../pages/surveyresult/SurveyResult'
import CreateExam from '../pages/my-course-exam/CreateExam'
import StudentList from '../pages/my-course-exam/StudentList'
import CourseOutcomesReport from '../pages/my-course/CourseOutcomesReport'
import SurveyResultSemester from '../pages/surveyresult/SurveyResultSemester'
import SurveyResultLecturer from '../pages/surveyresult/SurveyResultLecturer'
import OrganizationOutcomeContribution from '../pages/organizationoutcome/OrganizationOutcomeContribution'
//import {EditForm} from "../pages/questions/Add"
// import {TemplateForm} from "../pages/answertemplates/Add"
//import {EditAuditForm} from "../pages/audits/Planner"
//import {AuditDetails} from "../pages/audits/detail/auditdetail"

const PrivateRoutes = () => {
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
  const ModelListPage = lazy(() => import('../pages/modellist/Page'))
  const MethodListPage = lazy(() => import('../pages/methodlist/Page'))
  const RoleMethodListPage = lazy(() => import('../pages/rolemethodlist/Page'))
  const RoleModelListPage = lazy(() => import('../pages/rolemodellist/Page'))
  const MenuListPage = lazy(() => import('../pages/menulist/Page'))
  const MenuRolePage = lazy(() => import('../pages/menurole/Page'))
  const UserActionPage = lazy(() => import('../pages/useraction/Page'))
  const CulturePage = lazy(() => import('../pages/culture/Page'))
  const TranslationPage = lazy(() => import('../pages/translation/Page'))
  const ApplicationUserPage = lazy(() => import('../pages/applicationuser/Page'))
  const ApplicationRolePage = lazy(() => import('../pages/applicationrole/Page'))
  const ApplicationUserRolePage = lazy(() => import('../pages/applicationuserrole/Page'))
  const ApplicationUserClaimPage = lazy(() => import('../pages/applicationuserclaim/Page'))
  const ApplicationRoleClaimPage = lazy(() => import('../pages/applicationroleclaim/Page'))
  const TenantPage = lazy(() => import('../pages/tenant/Page'))
  const AnswerPage = lazy(() => import('../pages/answer/Page'))
  const AnswerQuestionItemPage = lazy(() => import('../pages/answerquestionitem/Page'))
  const CodePage = lazy(() => import('../pages/code/Page'))
  const CoursePage = lazy(() => import('../pages/course/Page'))
    const MyCoursePage = lazy(() => import('../pages/my-course/Page'))
    const MyCourseBPage = lazy(() => import('../pages/my-course-bolum/Page'))
    const MyCourseExamPage = lazy(() => import('../pages/my-course-exam/Page'))
  const CourseOutcomePage = lazy(() => import('../pages/courseoutcome/Page'))
  const ExamPage = lazy(() => import('../pages/exam/Page'))
  const ExamQuestionPage = lazy(() => import('../pages/examquestion/Page'))
  const OrganizationPage = lazy(() => import('../pages/organization/Page'))
  const OrganizationEducationPurposePage = lazy(
    () => import('../pages/organizationeducationpurpose/Page')
  )
  const OrganizationOutcomePage = lazy(() => import('../pages/organizationoutcome/Page'))
  const OutcomePointPage = lazy(() => import('../pages/outcomepoint/Page'))
  const QuestionBankPage = lazy(() => import('../pages/questionbank/Page'))
  const QuestionItemPage = lazy(() => import('../pages/questionitem/Page'))
  const SemesterPage = lazy(() => import('../pages/semester/Page'))
  const SemesterCoursePage = lazy(() => import('../pages/semestercourse/Page'))
  const StudentPage = lazy(() => import('../pages/student/Page'))
  const StudentSemesterCoursePage = lazy(() => import('../pages/studentsemestercourse/Page'))
  const SurveyPage = lazy(() => import('../pages/survey/Page'))
  const SurveyApplicationPage = lazy(() => import('../pages/surveyapplication/Page'))
  const SurveyFrequencyPage = lazy(() => import('../pages/surveyfrequency/Page'))
  const SurveyQuestionPage = lazy(() => import('../pages/surveyquestion/Page'))
  const SurveyQuestionTypePage = lazy(() => import('../pages/surveyquestiontype/Page'))
  const SurveyQuestionTypeAnswerPage = lazy(() => import('../pages/surveyquestiontypeanswer/Page'))
  const SurveyTypePage = lazy(() => import('../pages/surveytype/Page'))
  const CourseOutcomeQuestion = lazy(() => import('../pages/courseoutcomequestion/Page'))
  const ExamType = lazy(() => import('../pages/examtype/Page'))
  const Gender = lazy(() => import('../pages/gender/Page'))
  const Questiontype = lazy(() => import('../pages/questiontype/Page'))
    const SemesterCourseReport = lazy(() => import('../pages/semestercoursereport/Page'))
    const SemesterReport = lazy(() => import('../pages/semesterreport/Page'))
  const CourseOrganizationOutcomeMatrix = lazy(
    () => import('../pages/courseorganizationoutcomematrix/Page')
  )
  const Setting = lazy(() => import('../pages/setting/Page'))

  // const AuditsPage = lazy(() => import('../pages/audit/Page'))
  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/' />} />
        {/* Pages */}

        <Route path='dashboard' element={<DashboardPage />} />
        <Route path='/addupdatesurvey' element={<EditSurveyForm />} />
        <Route path='/surveyresult' element={<SurveyResult />} />
              <Route path='/createexam' element={<CreateExam />} />
              <Route path='/studentlist' element={<StudentList />} />
              <Route path='/courseoutcomesreport' element={<CourseOutcomesReport />} />
              <Route path='/surveyresultsemester' element={<SurveyResultSemester />} />
              <Route path='/surveyresultlecturer' element={<SurveyResultLecturer />} />
              <Route path='/organizationoutcomescontribution' element={<OrganizationOutcomeContribution />} />

        {/* Lazy Modules */}
        <Route
          path='crafted/pages/profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/pages/wizards/*'
          element={
            <SuspensedView>
              <WizardsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/widgets/*'
          element={
            <SuspensedView>
              <WidgetsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/account/*'
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/chat/*'
          element={
            <SuspensedView>
              <ChatPage />
            </SuspensedView>
          }
        />
        <Route
          path='courseoutcomequestion/*'
          element={
            <SuspensedView>
              <CourseOutcomeQuestion />
            </SuspensedView>
          }
        />
        <Route
          path='examtype/*'
          element={
            <SuspensedView>
              <ExamType />
            </SuspensedView>
          }
        />
        <Route
          path='gender/*'
          element={
            <SuspensedView>
              <Gender />
            </SuspensedView>
          }
        />
        <Route
          path='semestercoursereport/*'
          element={
            <SuspensedView>
              <SemesterCourseReport />
            </SuspensedView>
          }
        />

        <Route
          path='questiontype/*'
          element={
            <SuspensedView>
              <Questiontype />
            </SuspensedView>
          }
        />
        <Route
          path='modellist/*'
          element={
            <SuspensedView>
              <ModelListPage />
            </SuspensedView>
          }
        />
        <Route
          path='methodlist/*'
          element={
            <SuspensedView>
              <MethodListPage />
            </SuspensedView>
          }
        />
        <Route
          path='rolemethodlist/*'
          element={
            <SuspensedView>
              <RoleMethodListPage />
            </SuspensedView>
          }
        />
        <Route
          path='rolemodellist/*'
          element={
            <SuspensedView>
              <RoleModelListPage />
            </SuspensedView>
          }
        />
        <Route
          path='menulist/*'
          element={
            <SuspensedView>
              <MenuListPage />
            </SuspensedView>
          }
        />
        <Route
          path='menurole/*'
          element={
            <SuspensedView>
              <MenuRolePage />
            </SuspensedView>
          }
        />
        <Route
          path='useraction/*'
          element={
            <SuspensedView>
              <UserActionPage />
            </SuspensedView>
          }
        />
        <Route
          path='culture/*'
          element={
            <SuspensedView>
              <CulturePage />
            </SuspensedView>
          }
        />
        <Route
          path='translation/*'
          element={
            <SuspensedView>
              <TranslationPage />
            </SuspensedView>
          }
        />
        <Route
          path='applicationuser/*'
          element={
            <SuspensedView>
              <ApplicationUserPage />
            </SuspensedView>
          }
        />
        <Route
          path='applicationrole/*'
          element={
            <SuspensedView>
              <ApplicationRolePage />
            </SuspensedView>
          }
        />
        <Route
          path='applicationuserrole/*'
          element={
            <SuspensedView>
              <ApplicationUserRolePage />
            </SuspensedView>
          }
        />
        <Route
          path='applicationuserclaim/*'
          element={
            <SuspensedView>
              <ApplicationUserClaimPage />
            </SuspensedView>
          }
        />
        <Route
          path='applicationroleclaim/*'
          element={
            <SuspensedView>
              <ApplicationRoleClaimPage />
            </SuspensedView>
          }
        />
        <Route
          path='tenant/*'
          element={
            <SuspensedView>
              <TenantPage />
            </SuspensedView>
          }
        />
        <Route
          path='answer/*'
          element={
            <SuspensedView>
              <AnswerPage />
            </SuspensedView>
          }
        />
        <Route
          path='answerquestionitem/*'
          element={
            <SuspensedView>
              <AnswerQuestionItemPage />
            </SuspensedView>
          }
        />
        <Route
          path='code/*'
          element={
            <SuspensedView>
              <CodePage />
            </SuspensedView>
          }
        />
        <Route
          path='course/*'
          element={
            <SuspensedView>
              <CoursePage />
            </SuspensedView>
          }
        />
        <Route
          path='my-course/*'
          element={
            <SuspensedView>
              <MyCoursePage />
            </SuspensedView>
          }
              />
        <Route
          path='my-course-b/*'
          element={
            <SuspensedView>
              <MyCourseBPage />
            </SuspensedView>
          }
              />
              <Route
                  path='my-course-exam/*'
                  element={
                      <SuspensedView>
                          <MyCourseExamPage />
                      </SuspensedView>
                  }
              />
        <Route
          path='courseoutcome/*'
          element={
            <SuspensedView>
              <CourseOutcomePage />
            </SuspensedView>
          }
        />
        <Route
          path='exam/*'
          element={
            <SuspensedView>
              <ExamPage />
            </SuspensedView>
          }
        />
        <Route
          path='examquestion/*'
          element={
            <SuspensedView>
              <ExamQuestionPage />
            </SuspensedView>
          }
        />
        <Route
          path='organization/*'
          element={
            <SuspensedView>
              <OrganizationPage />
            </SuspensedView>
          }
        />
        <Route
          path='organizationeducationpurpose/*'
          element={
            <SuspensedView>
              <OrganizationEducationPurposePage />
            </SuspensedView>
          }
        />
        <Route
          path='organizationoutcome/*'
          element={
            <SuspensedView>
              <OrganizationOutcomePage />
            </SuspensedView>
          }
        />
        <Route
          path='outcomepoint/*'
          element={
            <SuspensedView>
              <OutcomePointPage />
            </SuspensedView>
          }
        />
        <Route
          path='questionbank/*'
          element={
            <SuspensedView>
              <QuestionBankPage />
            </SuspensedView>
          }
        />
        <Route
          path='questionitem/*'
          element={
            <SuspensedView>
              <QuestionItemPage />
            </SuspensedView>
          }
        />
        <Route
          path='semester/*'
          element={
            <SuspensedView>
              <SemesterPage />
            </SuspensedView>
          }
        />
        <Route
          path='semestercourse/*'
          element={
            <SuspensedView>
              <SemesterCoursePage />
            </SuspensedView>
          }
        />
        <Route
          path='student/*'
          element={
            <SuspensedView>
              <StudentPage />
            </SuspensedView>
          }
        />
        <Route
          path='studentsemestercourse/*'
          element={
            <SuspensedView>
              <StudentSemesterCoursePage />
            </SuspensedView>
          }
        />
        <Route
          path='survey/*'
          element={
            <SuspensedView>
              <SurveyPage />
            </SuspensedView>
          }
        />
        <Route
          path='surveyapplication/*'
          element={
            <SuspensedView>
              <SurveyApplicationPage />
            </SuspensedView>
          }
        />
        <Route
          path='surveyfrequency/*'
          element={
            <SuspensedView>
              <SurveyFrequencyPage />
            </SuspensedView>
          }
        />
        <Route
          path='surveyquestion/*'
          element={
            <SuspensedView>
              <SurveyQuestionPage />
            </SuspensedView>
          }
              />
              <Route
                  path='semesterreport/*'
                  element={
                      <SuspensedView>
                          <SemesterReport />
                      </SuspensedView>
                  }
              />
        <Route
          path='surveyquestiontype/*'
          element={
            <SuspensedView>
              <SurveyQuestionTypePage />
            </SuspensedView>
          }
        />
        <Route
          path='surveyquestiontypeanswer/*'
          element={
            <SuspensedView>
              <SurveyQuestionTypeAnswerPage />
            </SuspensedView>
          }
        />
        <Route
          path='surveytype/*'
          element={
            <SuspensedView>
              <SurveyTypePage />
            </SuspensedView>
          }
        />
        <Route
          path='courseorganizationoutcomematrix/*'
          element={
            <SuspensedView>
              <CourseOrganizationOutcomeMatrix />
            </SuspensedView>
          }
        />
        <Route
          path='setting/*'
          element={
            <SuspensedView>
              <Setting />
            </SuspensedView>
          }
        />

        <Route
          path='dashboard/*'
          element={
            <SuspensedView>
              <DashboardPage />
            </SuspensedView>
          }
        />
        {/* <Route
          path='apps/audit/*'
          element={
            <SuspensedView>
              <AuditsPage />
            </SuspensedView>
          }
        />
         */}
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return (
    <Suspense fallback={<TopBarProgress />}>
      <DisableSidebar>{children}</DisableSidebar>
    </Suspense>
  )
}

export {PrivateRoutes}
